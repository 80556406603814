import React, { useContext, useEffect, useState } from "react";
import Wrapper from "./Wrapper";
import RichTextEditor from "../../components/RichTextEditor";
import { Email } from "../../types/EmailTypes";
import { createEmail, getEmail, updateEmail } from "../../lib/firebase/emails";
import { UserContext } from "../../contexts/UserContext";
import { BoxSeamFill, MagnetFill, MegaphoneFill } from "react-bootstrap-icons";
import toast from "react-hot-toast";
import Notification from "../../components/Notification";
import { Wizard } from "../../types/WizardTypes";
import { ProjectContext } from "../../contexts/ProjectContext";
import { useTranslation } from "react-i18next";
import { PRIMARY_COLOR } from "../../utils/constants";

interface Props {
  wizard: Wizard;
  setEmailId: (id: string | null) => void;
}

const Step4 = ({ wizard, setEmailId }: Props) => {
  const { t, ready } = useTranslation("Wizard.Step4");
  const CURRENT_STEP = 4;
  const { user } = useContext(UserContext) as any;
  const { project } = useContext(ProjectContext) as any;
  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState([]) as any;
  const [selectedTemplate, setSelectedTemplate] = useState(null) as any;
  const [email, setEmail] = useState<Email>();

  useEffect(() => {
    if (!selectedTemplate) return;
    setEmail((prevEmail: any) => ({
      ...prevEmail,
      subject: selectedTemplate.subject,
      body: selectedTemplate.body,
    }));
  }, [selectedTemplate]);

  useEffect(() => {
    const templates = [
      {
        id: 1,
        name: t("services"),
        icon: <BoxSeamFill />,
        subject: t("subject1"),
        body: t("body1"),
      },
      {
        id: 2,
        name: t("leadMagnet"),
        icon: <MagnetFill />,
        subject: t("subject2"),
        body: t("body2"),
      },
      {
        id: 3,
        name: t("collaboration"),
        icon: <MegaphoneFill />,
        subject: t("subject3"),
        body: t("body3"),
      },
    ];
    const selectedTemplate = templates[0];

    setTemplates(templates);
    setSelectedTemplate(selectedTemplate);

    setEmail({
      id: null,
      version: 3,
      title: t("myFirstEmail"),
      subject: selectedTemplate.subject,
      body: selectedTemplate.body,
      analysis: wizard.analysisId,
      createdAt: new Date(),
      sent: 0,
      opens: 0,
      skipped: 0,
      unsubscribed: 0,
      clicks: 0,
      uniqueClicks: 0,
      senders: [wizard.senderId],
      followUp: {
        isAFollowUpEmail: false,
        followUpMode: null,
        email: null,
      },
      status: "NOT_STARTED",
      project: project?.id || null,
    });
  }, [ready]);

  const handleBodyChange = (value: any) => {
    if (!value) return;
    setEmail((prev: any) => ({ ...prev, body: value }));
  };

  const handleSubjectChange = (e: any) => {
    setEmail((prev: any) => ({ ...prev, subject: e.target.value }));
  };

  const fetchEmail = async (emailId: string) => {
    try {
      setLoading(true);
      const email = await getEmail(user.firebaseUser.uid, emailId);
      if (!email) {
        return await setEmailId(null);
      }
      setEmail(email);
    } catch (err: any) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleNext = async () => {
    try {
      setLoading(true);
      if (wizard.emailId) {
        await updateEmail(user.firebaseUser.uid, wizard.emailId, email);
      } else {
        if (!email) throw new Error("Email not found");
        let newEmailId = await createEmail(user.firebaseUser.uid, email);
        await setEmailId(newEmailId);
      }
    } catch (err: any) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (wizard.emailId) {
      fetchEmail(wizard.emailId);
    }
  }, [wizard.emailId]);

  return (
    <Wrapper
      step={CURRENT_STEP}
      title={t("prepareYourEmail")}
      subtitle={t("prepareEmailSubtitle")}
      beforeNext={handleNext}
      loading={loading}
    >
      <label htmlFor="email-subject">{t("selectTemplate")}</label>
      <div className="row">
        {templates &&
          templates.map((template: any, index: number) => {
            return (
              <div className="col-lg-4 mb-4" key={index} onClick={() => setSelectedTemplate(templates[index])}>
                <div className={`card ${template.id === selectedTemplate.id && "border-primary"}`} style={{ cursor: "pointer" }}>
                  <div className={`card-body text-center ${template.id === selectedTemplate.id && "text-primary"}`}>
                    <h2 className={`${template.id === selectedTemplate.id && "text-primary"}`}>{template.icon}</h2>
                    {template.name}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <Notification message={t("moreOptionsLater")} type="info" />
      <div className="form-group mt-5">
        <label htmlFor="email-subject">{t("subject")}</label>
        <input
          type="text"
          name="subject"
          disabled={false}
          onChange={handleSubjectChange}
          value={email?.subject}
          className="form-control"
          id="email-subject"
          placeholder={t("quickConsultation")}
        />
      </div>
      <label>
        {t("message")} <br />
        1. <span style={{ color: "#00c9a7" }}>{t("replaceWithYourInfo")}</span>
        <br />
        2. <span style={{ color: PRIMARY_COLOR }}>{t("examplesForInspiration")}</span>
        <br />
        3. {t("whenDone")}{" "}
        <u>
          <i>T</i>
        </u>
        <sub>
          <b>x</b>
        </sub>
      </label>
      <RichTextEditor value={email?.body} setValue={handleBodyChange} showVariables={true} showEmailAi={false} showTemplates={false} />
      <Notification message={t("dontWorry")} type="info" />
    </Wrapper>
  );
};

export default Step4;

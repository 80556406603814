import { BACKEND_URL } from "../../utils/constants";
import makeRequest from "../client";

export const testSender = async (senderId: string, jwt: string): Promise<any> => {
  try {
    const response = await makeRequest({
      url: `${BACKEND_URL}/v1/email/test-sender`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
      body: {
        senderId: senderId,
      },
    });

    return response;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { removeAnalysis } from "../../lib/daos/analysisDAO";
import { Funnel, Plus, Search, Trash } from "react-bootstrap-icons";
import { toast } from "react-hot-toast";
import { deleteAnalysis, getAllAnalysis as getAllCloudAnalyses } from "../../lib/firebase/analysis";
import { UserContext } from "../../contexts/UserContext";
import { MODE, STATUS, STATUS_TYPES } from "../../utils/constants";
import Skeleton from "react-loading-skeleton";
import { ProjectContext } from "../../contexts/ProjectContext";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import useAnalysis from "../../hooks/useAnalysis";
import { useTranslation } from "react-i18next";

function AnalysisTable() {
  const { t } = useTranslation("AnalysisLoop");
  const [analyses, setAnalyses] = useState([]) as any;
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [analysisToDelete, setAnalysisToDelete] = useState(null) as any;
  const [selectedStatuses, setSelectedStatuses] = useState([]) as any;

  const { user } = useContext(UserContext) as any;
  const { project } = useContext(ProjectContext) as any;
  const { createAnalysis } = useAnalysis(null);

  const [deletionIsLoading, setDeletionIsLoading] = useState(false);

  const handleCloseDelete = () => {
    setShowDeleteModal(false);
    setAnalysisToDelete(null);
  };
  const handleShowDelete = (analysis: any) => {
    setAnalysisToDelete(analysis);
    setShowDeleteModal(true);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredAnalyses = analyses.filter((analysis: any) => analysis.name.toLowerCase().includes(searchTerm.toLowerCase()));

  const fetchAllAnalyses = async (showLoading = true) => {
    try {
      showLoading && setLoading(true);
      const analyses = await getAllCloudAnalyses(user?.firebaseUser?.uid, selectedStatuses, project?.id);
      analyses.forEach((ca: any) => (ca.createdAt = ca.createdAt.toDate()));
      analyses.sort((a: any, b: any) => b.createdAt - a.createdAt);
      setAnalyses(analyses);
    } catch (err: any) {
      toast.error(t("notificationErrorFetching") || err.message);
    } finally {
      showLoading && setLoading(false);
    }
  };

  const handleStatusChange = (status: string) => {
    setSelectedStatuses((prev: any) => {
      // Verifica si el estado ya está en el arreglo
      const isStatusSelected = prev.includes(status);
      if (isStatusSelected) {
        // Si ya está seleccionado, lo elimina
        return prev.filter((s: any) => s !== status);
      } else {
        // Si no está seleccionado, lo añade
        return [...prev, status];
      }
    });
  };

  const handleDeleteAnalysis = async (analysis: any) => {
    try {
      setDeletionIsLoading(true);
      if (analysis.isCloud) await deleteAnalysis(user?.firebaseUser?.uid, analysis.id);
      else await removeAnalysis(analysis.id);
      await fetchAllAnalyses(false);
    } catch (err: any) {
      toast.error(t("notificationErrorDeleting") || err.message);
    } finally {
      setDeletionIsLoading(false);
    }
  };

  useEffect(() => {
    if (!user.firebaseUser) return;
    fetchAllAnalyses();
  }, [user, project, selectedStatuses]);

  if (!user.firebaseUser || loading) {
    return (
      <>
        <div className="card">
          <div className="card-header">
            <h4 className="card-header-title">{t("cardHeaderTitle")}</h4>
          </div>

          <div className="table-responsive datatable-custom">
            <div id="datatable_wrapper" className="dataTables_wrapper no-footer">
              <div id="datatable_filter" className="dataTables_filter">
                <label>
                  {t("searchLabel")}
                  <input type="search" aria-controls="datatable" />
                </label>
              </div>
              <table className="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
                <thead className="thead-light">
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">{t("tableHeaders.analysisName")}</th>
                    <th scope="col">{t("tableHeaders.usersEntered")}</th>
                    <th scope="col">{t("tableHeaders.usersAnalyzed")}</th>
                    <th scope="col">{t("tableHeaders.status")}</th>
                    <th scope="col">{t("tableHeaders.completion")}</th>
                    <th scope="col">{t("tableHeaders.actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {Array(5)
                    .fill(0)
                    .map((_, index) => (
                      <tr role="row" className="odd" key={index}>
                        <td className="table-column-pr-0">
                          <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" />
                            <label className="custom-control-label" />
                          </div>
                        </td>
                        <td className="p-0">
                          <div className="custom-control custom-checkbox">
                            <Skeleton />
                          </div>
                        </td>
                        <td className="p-0">
                          <div className="custom-control custom-checkbox">
                            <Skeleton />
                          </div>
                        </td>
                        <td className="p-0">
                          <div className="custom-control custom-checkbox">
                            <Skeleton />
                          </div>
                        </td>
                        <td className="p-0">
                          <div className="custom-control custom-checkbox">
                            <Skeleton />
                          </div>
                        </td>
                        <td className="p-0">
                          <div className="custom-control custom-checkbox">
                            <Skeleton />
                          </div>
                        </td>
                        <td className="p-0">
                          <div className="custom-control custom-checkbox">
                            <Skeleton />
                          </div>
                        </td>
                        <td className="p-0">
                          <div className="custom-control custom-checkbox">
                            <Skeleton />
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    );
  }

  if (analyses.length === 0 && !selectedStatuses.length)
    return (
      <div className="card">
        <div className="card-header">
          <h4 className="card-header-title">{t("cardHeaderTitle")}</h4>
        </div>
        <div className="d-flex justify-content-center align-items-center py-5" style={{ minHeight: "50vh" }}>
          <div className="col-sm-7 col-md-5 text-center">
            <img className="img-fluid mb-5" style={{ maxWidth: 350 }} src="/assets/svg/illustrations/yelling.svg" alt="Yelling" />
            <h1>{t("welcomeTitle")}</h1>
            <p>{t("welcomeMessage")}</p>
            <button className="btn btn-primary" onClick={createAnalysis}>
              <Plus size="20" className="mr-1" /> <span className="align-middle">{t("createFirstAnalysisButton")}</span>
            </button>
          </div>
        </div>
      </div>
    );

  return (
    <div className="card">
      {/* Header */}
      <div className="card-header">
        <div className="row justify-content-between align-items-center flex-grow-1">
          <div className="col-sm-6 col-md-4 mb-3 mb-sm-0">
            <form>
              {/* Search */}
              <div className="input-group input-group-merge input-group-flush">
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    <Search />
                  </div>
                </div>
                <input
                  id="datatableSearch"
                  type="search"
                  className="form-control"
                  placeholder={t("searchPlaceholder")}
                  aria-label="Search analyses"
                  onChange={handleSearchChange}
                />
              </div>
              {/* End Search */}
            </form>
          </div>
          <div className="col-sm-6">
            <div className="d-flex justify-content-sm-end align-items-center">
              <div className="dropdown d-none d-md-block mr-2">
                <button
                  className="btn btn-white dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <Funnel className="mr-2" />
                  <span className="align-middle">
                    Status{" "}
                    {selectedStatuses && selectedStatuses.length > 0 && (
                      <span className="badge bg-soft-primary text-primary ms-1">{selectedStatuses.length}</span>
                    )}
                  </span>
                </button>
                <div className="dropdown-menu dropdown-menu-lg-end px-2" aria-labelledby="dropdownMenuButton">
                  <div className="form-check dropdown-item px-5">
                    <input
                      type="checkbox"
                      id="formCheck0"
                      className="form-check-input"
                      checked={selectedStatuses.includes(STATUS.RUNNING)}
                      onChange={() => handleStatusChange(STATUS.RUNNING)}
                    />
                    <label className="form-check-label" htmlFor="formCheck0">
                      Running
                    </label>
                  </div>
                  <div className="form-check dropdown-item px-5">
                    <input
                      type="checkbox"
                      id="formCheck0"
                      className="form-check-input"
                      checked={selectedStatuses.includes(STATUS.COMPLETED)}
                      onChange={() => handleStatusChange(STATUS.COMPLETED)}
                    />
                    <label className="form-check-label" htmlFor="formCheck0">
                      Completed
                    </label>
                  </div>
                  <div className="form-check dropdown-item px-5">
                    <input
                      type="checkbox"
                      id="formCheck1"
                      className="form-check-input"
                      checked={selectedStatuses.includes(STATUS.NOT_STARTED)}
                      onChange={() => handleStatusChange(STATUS.NOT_STARTED)}
                    />
                    <label className="form-check-label" htmlFor="formCheck1">
                      Not Started
                    </label>
                  </div>
                  <div className="form-check dropdown-item px-5">
                    <input
                      type="checkbox"
                      id="formCheck2"
                      className="form-check-input"
                      checked={selectedStatuses.includes(STATUS.PAUSED)}
                      onChange={() => handleStatusChange(STATUS.PAUSED)}
                    />
                    <label className="form-check-label" htmlFor="formCheck2">
                      Paused
                    </label>
                  </div>
                  <div className="form-check dropdown-item px-5">
                    <input
                      type="checkbox"
                      id="formCheck3"
                      className="form-check-input"
                      checked={selectedStatuses.includes(STATUS.ERROR)}
                      onChange={() => handleStatusChange(STATUS.ERROR)}
                    />
                    <label className="form-check-label" htmlFor="formCheck3">
                      Error
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Row */}
      </div>
      {/* End Header */}
      {/* Table */}
      <div className="table-responsive datatable-custom" style={{ maxHeight: 600 }}>
        <div id="datatable_wrapper" className="dataTables_wrapper no-footer">
          <div id="datatable_filter" className="dataTables_filter">
            <label>
              {t("searchLabel")}
              <input type="search" aria-controls="datatable" />
            </label>
          </div>

          <table className="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
            <thead className="thead-light" style={{ position: "sticky", width: "100%", top: "-1px", zIndex: "5" }}>
              <tr>
                <th scope="col"></th>
                <th scope="col">{t("tableHeaders.analysisName")}</th>
                <th scope="col">{t("tableHeaders.usersEntered")}</th>
                <th scope="col">{t("tableHeaders.usersAnalyzed")}</th>
                <th scope="col">{t("tableHeaders.status")}</th>
                <th scope="col">{t("tableHeaders.completion")}</th>
                <th scope="col">{t("tableHeaders.actions")}</th>
              </tr>
            </thead>
            <tbody>
              {filteredAnalyses.map((analysis: any, index: Number) => (
                <Row key={index} analysis={analysis} deletionIsLoading={deletionIsLoading} handleShowDeleteModal={handleShowDelete} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="card-footer">
        {t("showingResults_1")} {filteredAnalyses.length} {t("showingResults_2")}
      </div>
      {/* End Table */}
      <DeleteConfirmationModal
        loading={deletionIsLoading}
        show={showDeleteModal}
        close={handleCloseDelete}
        data={analysisToDelete}
        onConfirm={handleDeleteAnalysis}
        text={t("deleteConfirmationText")}
      />
    </div>
  );
}

function Row({ analysis, handleShowDeleteModal, deletionIsLoading }: any) {
  const getTotalUsersEntered = () => {
    const modeMapping = {
      [MODE.FOLLOWERS]: analysis?.selectedItem?.follower_count || analysis?.selectedItem?.edge_followed_by?.count,
      [MODE.FOLLOWING]: analysis?.selectedItem?.following_count || analysis?.selectedItem?.edge_following?.count,
      [MODE.LIKERS]: analysis?.selectedItem?.like_count,
      [MODE.COMMENTERS]: analysis?.selectedItem?.comment_count,
      [MODE.HASHTAG]: -1,
      [MODE.LOCATION]: -1,
    };

    return modeMapping[analysis.mode] || 0;
  };

  const { t } = useTranslation("AnalysisLoop");

  return (
    <tr role="row" className="odd">
      <td className="table-column-pr-0">
        <div className="custom-control custom-checkbox">
          <input type="checkbox" className="custom-control-input" id={`analysesCheck${analysis.id}`} />
          <label className="custom-control-label" htmlFor={`analysesCheck${analysis.id}`} />
        </div>
      </td>
      <td className="table-column-pl-0">
        <Link className="d-flex" to={`/analysis/${analysis.id}`}>
          <div className="ml-3">
            <span className="d-block h5 text-hover-primary mb-0">{analysis.name}</span>
            <span className="d-block font-size-sm text-body">
              {t("created")} <Moment fromNow>{analysis.createdAt}</Moment>.
            </span>
          </div>
        </Link>
      </td>
      <td>
        <div className="d-flex align-items-center">
          {getTotalUsersEntered() === -1 ? "-" : getTotalUsersEntered().toLocaleString("es-ES")}
        </div>
      </td>
      <td>{(analysis?.prospectsCount || 0).toLocaleString("es-ES")}</td>
      <td>
        {STATUS_TYPES.map(({ status, color }: any) => {
          if (analysis.status === status) {
            return (
              <React.Fragment key={status}>
                <span key={status} className={`legend-indicator ${color}`} />
                <span className="align-middle">{status.replace("_", " ")}</span>
              </React.Fragment>
            );
          }
          return null;
        })}
      </td>
      <td>
        <div className="d-flex align-items-center">
          <span className="font-size-sm mr-2">
            {getTotalUsersEntered() <= 0 ? "" : ((analysis.prospectsCount / getTotalUsersEntered()) * 100).toFixed(0)}%
          </span>

          <div className="progress table-progress">
            {STATUS_TYPES.map(({ status, color }: any) => {
              if (analysis.status === status) {
                return (
                  <div
                    key={status}
                    className={`progress-bar ${color}`}
                    role="progressbar"
                    style={{
                      width: `${getTotalUsersEntered() === -1 ? 0 : (analysis.prospectsCount / getTotalUsersEntered()) * 100}%`,
                    }}
                    aria-valuenow={getTotalUsersEntered() === -1 ? 0 : (analysis.prospectsCount / getTotalUsersEntered()) * 100}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                );
              }
              return null;
            })}
          </div>
        </div>
      </td>
      <td>
        <button className="btn btn-white" onClick={() => handleShowDeleteModal(analysis)} disabled={deletionIsLoading}>
          <Trash />
        </button>
      </td>
    </tr>
  );
}

export default AnalysisTable;

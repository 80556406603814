import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function ConfirmModal({ show, setShow, loading, text, onConfirm }: any) {
  const { t } = useTranslation("Component.ConfirmModal");

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header>
        <Modal.Title>{t("confirm")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{text}</Modal.Body>
      <Modal.Footer>
        <Button variant="white" onClick={() => setShow(false)} disabled={loading}>
          {t("cancel")}
        </Button>
        <Button variant="primary" disabled={loading} onClick={onConfirm}>
          {t("confirm")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmModal;

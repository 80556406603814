import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Breadcumb from "../../components/Breadcumb";
import { auth } from "../../lib/firebase/firebase";
import toast from "react-hot-toast";
import Form from "./Form";
import {
  createSender as createFirestoreSender,
  updateSender as updateFirestoreSender,
  getSender as getFirestoreSender,
} from "../../lib/firebase/senders";
import { Sender as SenderType } from "../../types/SenderTypes";
import { Book, ExclamationTriangleFill } from "react-bootstrap-icons";
import { ProjectContext } from "../../contexts/ProjectContext";
import { useTranslation } from "react-i18next";

function Header(props: any) {
  const { t } = useTranslation("Sender.index");
  return (
    <div className="row mb-4">
      <div className="col-sm-auto">
        <h1 className="page-header-title">{t("senderSettings")}</h1>
      </div>
    </div>
  );
}

function Sender(props: any) {
  let { id: paramsId } = useParams();
  let id = props.id || paramsId;
  const { provider } = useParams<{ provider: string }>();
  const { project } = useContext(ProjectContext) as any;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [user] = useState(auth.currentUser) as any;
  const [saved, setSaved] = useState(true);
  const { t } = useTranslation("Sender.index");
  const [sender, setSender] = useState<SenderType>({
    name: "",
    email: "",
    provider: provider as string,
    messagesPerDay: 25,
    nextAvailableTs: 0,
    delayBetweenEmails: 5,
    lastEmailSent: null,
    project: null,
    signature: `<p class="ql-align-center"><a href="{{&nbsp;unsubscribe_link&nbsp;}}" rel="noopener noreferrer" target="_blank"><span class="ql-variable">Unsubscribe</span></a></p>`,
    isValid: true,
  });

  const getSenderFromFirestore = async (id: any) => {
    try {
      setLoading(true);
      const sender = await getFirestoreSender(user.uid, id);
      if (!sender) return navigate("/senders");
      setSender(sender);
    } catch (err: any) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const updateSender = async (id: any) => {
    if (!isValid()) return toast.error(t("fillAllFields"));
    try {
      sender.isValid = true; // Force validation
      await updateFirestoreSender(user.uid, id, sender);
      toast.success(t("senderUpdated"));
    } catch (err: any) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const createSender = async () => {
    if (!isValid()) return toast.error(t("fillAllFields"));
    try {
      setLoading(true);
      if (provider === undefined) {
        return toast.error(t("providerNotDefined"));
      }
      sender.provider = provider;
      sender.project = project?.id || null;
      const newSender = await createFirestoreSender(user.uid, sender);
      toast.success(t("senderCreated"));
      return navigate("/senders/" + newSender.id);
    } catch (err: any) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const isValid = () => {
    if (!sender.name) return false;
    if (!sender.email) return false;
    if (!sender.messagesPerDay) return false;
    if (!sender.delayBetweenEmails) return false;
    if (!sender.provider) return false;
    return true;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    try {
      if (!id) createSender();
      else updateSender(id);
      setSaved(true);
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const handleCancel = () => {
    if (props.handleCancel) {
      props.handleCancel();
      return;
    }
    navigate("/senders");
  };

  useEffect(() => {
    if (id) {
      getSenderFromFirestore(id);
    }
  }, [id]);

  return (
    <div>
      {!props.focusMode && (
        <Breadcumb
          items={[
            { to: "/emails", title: t("emails") },
            { to: "/senders", title: t("senders") },
            { to: "#", title: sender.name },
          ]}
        />
      )}

      {!props.focusMode && <Header handleSubmit={handleSubmit} loading={loading} />}

      {sender.isValid === false && (
        <div className="alert alert-soft-danger" role="alert">
          <ExclamationTriangleFill /> SMTP Failure: RTPS-CE:- Connection failed
        </div>
      )}

      <div className="row justify-content-lg-center">
        <div className={props.focusMode ? "" : "col-lg-8"}>
          <Form
            id={id}
            loading={loading}
            sender={sender}
            setEmailAddress={setSender}
            updateSender={updateSender}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            setSaved={setSaved}
            saved={saved}
          />
        </div>
        {!props.focusMode && (
          <div className="col-lg-4">
            <div className="card">
              <div className="card-header">
                <h4 className="card-header-title">{t("needHelp")}</h4>
              </div>
              <div className="card-body">
                {sender.provider === "sendgrid" && (
                  <div style={{ position: "relative", paddingBottom: "60.60606060606061%", height: 0 }}>
                    <iframe
                      src="https://www.loom.com/embed/24232be4c8254c42bd32e467fc6504af?sid=157a2d47-f51c-4847-afaf-01f0f4278d29"
                      title="Sendgrid Instructions"
                      style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", borderRadius: 10, border: 0 }}
                    />
                  </div>
                )}
                {sender.provider === "google" && (
                  <div style={{ position: "relative", paddingBottom: "64.82593037214886%", height: 0 }}>
                    <iframe
                      src="https://www.loom.com/embed/54972b8b828d4e53a971a9aedb814bb1?sid=67877b96-a833-48ba-b84f-036edea55082"
                      title="Gmail Instructions"
                      style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", borderRadius: 10, border: 0 }}
                    />
                  </div>
                )}
                {sender.provider === "smtp" && (
                  <a
                    rel="noreferrer"
                    href="https://mailerfind.tawk.help/es/article/como-conectar-tu-cuenta-de-correo-corporativo"
                    target="_blank"
                  >
                    <Book className="mr-1" /> <span className="align-middle">{t("readGuide")} </span>
                  </a>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Sender;

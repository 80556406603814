import React, { useContext, useEffect, useState } from "react";
import { auth } from "../../lib/firebase/firebase";
import { ExclamationTriangleFill, PencilSquare, Plus, Trash } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { BACKEND_URL } from "../../utils/constants";
import Header from "../../components/Header";
import Skeleton from "react-loading-skeleton";
import { deleteSender, getSenders } from "../../lib/firebase/senders";
import toast from "react-hot-toast";
import AddNewSenderModal from "./AddNewSenderModal";
import { ProjectContext } from "../../contexts/ProjectContext";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import { useTranslation } from "react-i18next";

function Senders() {
  const { t } = useTranslation("Senders.index");
  const navigate = useNavigate();
  const [user] = useState(auth.currentUser) as any;
  const { project } = useContext(ProjectContext) as any;
  const [loading, setLoading] = useState(true);
  const [senders, setSenders] = useState([]);

  const [show, setShow] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [senderToDelete, setSenderToDelete] = useState(null) as any;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const fetchSenders = async () => {
    try {
      setLoading(true);
      const senders = await getSenders(user.uid, { project: project?.id || null });
      setSenders(senders);
    } catch (err: any) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSenders();
  }, [project]);

  const handleConnect = async (provider: any) => {
    if (provider === "smtp" || provider === "sendgrid") {
      return navigate(`/senders/new/${provider}`);
    }
    const jwt = await user?.getIdToken();
    const url = `${BACKEND_URL}/v1/auth/${provider}?idToken=${jwt}&project=${project?.id || null}`;
    window.open(url, "_blank");
    handleClose();
  };

  useEffect(() => {
    // Definir la función manejadora del evento
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        fetchSenders();
      }
    };

    // Agregar el listener al evento visibilitychange
    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Limpiar el listener cuando el componente se desmonte
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  /*
   * This function removes a document in the senders collection
   *
   * */
  const handleDisconnectAccount = async (id: any) => {
    try {
      setLoading(true);
      await deleteSender(user.uid, id);
      await fetchSenders();
    } catch (err: any) {
      toast.error(err.message || t("deleteError"));
    } finally {
      setLoading(false);
    }
  };

  const handleShowDeleteModal = (senderId: string) => {
    setShowDeleteModal(true);
    setSenderToDelete(senderId);
  };

  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const handleEditAccount = (id: any) => {
    navigate("/senders/" + id);
  };

  return (
    <>
      <Header
        title={t("title")}
        subtitle={t("subtitle")}
        breadCrumbItems={[
          { href: "/emails", title: t("breadcrumbEmails") },
          { href: "/senders", title: t("breadcrumbSenders") },
        ]}
      ></Header>
      <div id="connectedAccountsSection" className="card mb-3 mb-lg-5">
        <div className="card-header">
          <h4 className="card-title">{t("emailSenders")}</h4>
          <button className="text-right btn btn-primary" onClick={handleShow}>
            {t("addNewSender")}
          </button>
        </div>
        {/* Body */}
        <div className="card-body">
          <div className="list-group list-group-lg list-group-flush list-group-no-gutters">
            {loading ? (
              <>
                <div className="list-group-item">
                  <Skeleton height={40} />
                </div>
                <div className="list-group-item">
                  <Skeleton height={40} />
                </div>
                <div className="list-group-item">
                  <Skeleton height={40} />
                </div>
              </>
            ) : (
              <>
                {senders.length === 0 ? (
                  <div className="list-group-item">
                    <div className="media">
                      <div className="media-body">
                        <div className="row align-items-center">
                          <div className="col text-center">
                            <img
                              src="/assets/svg/illustrations/sending.svg"
                              alt="Empty"
                              className="img-fluid mb-4"
                              style={{ maxWidth: "10rem" }}
                            />
                            <p className="mb-0 text-center mb-4">{t("noSenders")}</p>
                            <button className="btn btn-primary" onClick={handleShow}>
                              <span className="mr-1">
                                <Plus size={24} />
                              </span>
                              <span className="align-middle">{t("addNewSender")}</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {senders.map((sender: any, index: any) => (
                      <div className="list-group-item" key={index}>
                        <div className="media">
                          {sender.provider === "google" && (
                            <img className="avatar avatar-sm mt-1 mr-3" src="./assets/img/logos/gmail.jpg" alt="Gmail Logo" />
                          )}
                          {sender.provider === "microsoft" && (
                            <img className="avatar avatar-sm mt-1 mr-3" src="./assets/img/logos/outlook.png" alt="Gmail Logo" />
                          )}
                          {sender.provider === "sendgrid" && (
                            <img className="avatar avatar-sm mt-1 mr-3" src="./assets/svg/brands/sendgrid.svg" alt="Sendgrid Logo" />
                          )}
                          {sender.provider === "smtp" && (
                            <img className="avatar avatar-sm mt-1 mr-3" src="./assets/svg/other.svg" alt="SMTP Logo" />
                          )}
                          <div className="media-body">
                            <div className="row align-items-center">
                              <div className="col">
                                <h5 className="mb-0" style={{ cursor: "pointer" }} onClick={() => handleEditAccount(sender.id)}>
                                  {sender.name}{" "}
                                  {sender.isValid === false && <ExclamationTriangleFill className="text-danger align-middle mb-1" />}
                                </h5>

                                <p className="font-size-sm mb-0">{sender.email}</p>
                              </div>
                              <div className="col">{sender.provider.toUpperCase()}</div>
                              <div className="col">
                                {sender.emailsSentToday} / {sender.messagesPerDay || 0}
                              </div>
                              <div className="col-auto">
                                <button className="btn btn-sm btn-white mr-2" onClick={() => handleEditAccount(sender.id)}>
                                  <PencilSquare />
                                </button>
                                <button className="btn btn-sm btn-white" onClick={() => handleShowDeleteModal(sender.id)}>
                                  <Trash />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </>
            )}
          </div>
          {/* End Form */}
        </div>
        {/* End Body */}
        <AddNewSenderModal
          show={show}
          handleClose={handleClose}
          handleConnectGmail={() => handleConnect("google")}
          handleConnectOutlook={() => handleConnect("microsoft")}
          handleConnectSendgrid={() => handleConnect("sendgrid")}
          handleConnectOther={() => handleConnect("smtp")}
        />
        <DeleteConfirmationModal
          show={showDeleteModal}
          close={handleCloseDeleteModal}
          onConfirm={handleDisconnectAccount}
          data={senderToDelete}
          loading={loading}
          text={t("deleteConfirmation")}
        />
      </div>
    </>
  );
}

export default Senders;

import { BACKEND_URL } from "../../utils/constants";
import makeRequest from "../client";

interface Email {
  emailId: string;
  to: string;
}

export const startCampaign = async (emailId: string, jwt: string): Promise<any> => {
  try {
    const response = await makeRequest({
      url: `${BACKEND_URL}/v1/email/start-campaign`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
      body: {
        emailId: emailId,
      },
    });

    return response;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const pauseCampaign = async (emailId: string, jwt: string): Promise<any> => {
  try {
    const response = await makeRequest({
      url: `${BACKEND_URL}/v1/email/pause-campaign`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
      body: {
        emailId: emailId,
      },
    });

    return response;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const sendExample = async (emailId: Email, to: string, jwt: string): Promise<any> => {
  try {
    const response = await makeRequest({
      url: `${BACKEND_URL}/v1/email/send-example`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
      body: {
        emailId: emailId,
        to: to,
      },
    });

    return response;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

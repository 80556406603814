import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { UserContext } from "../../contexts/UserContext";
import Header from "../../components/Header";
import { getExports } from "../../lib/firebase/exports";
import { Export } from "../../types/ExportTypes";
import Moment from "react-moment";
import { ArrowLeft, Clipboard, Download, QuestionCircle } from "react-bootstrap-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getAnalysisById } from "../../lib/firebase/analysis";

function Exports() {
  const { user } = useContext(UserContext) as any;
  const [exports, setExports] = useState<Export[]>([]);
  const [analysis, setAnalysis] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const { analysisId } = useParams();

  const { t } = useTranslation("Exports.index");

  const fetchData = async () => {
    try {
      setLoading(true);
      const exports = await getExports(user.firebaseUser.uid, analysisId);
      if (analysisId) {
        const analysis = await getAnalysisById(user.firebaseUser.uid, analysisId);
        setAnalysis(analysis);
      }
      setExports(exports);
    } catch (err: unknown) {
      if (err instanceof Error) {
        toast.error("Failed to fetch exports: " + err.message);
      } else {
        toast.error("An unknown error occurred");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCopyDownloadUrl = (url: string | null) => {
    if (!url) return;
    navigator.clipboard.writeText(url);
    toast.success("URL copied to clipboard");
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <Header
        title={t("title", { analysisName: analysis?.name })}
        subtitle={t("subtitle")}
        breadCrumbItems={[
          { title: t("analysis"), href: "/analysis-manager" },
          { title: analysis?.name, href: `/analysis/${analysis?.id}` },
          { title: t("exports") },
        ]}
      />
      <div className="card">
        <div className="card-header">
          <h4 className="card-header-title">{t("exports")}</h4>
        </div>
        <div className="card-body p-0">
          <div className="table-responsive datatable-custom" style={{ maxHeight: 450 }}>
            <table className="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
              {exports.length > 0 && (
                <thead className="thead-light font-weight-light" style={{ position: "sticky", top: 0, zIndex: 1 }}>
                  <tr>
                    <th></th>
                    <th>{t("id")}</th>
                    <th>{t("status")}</th>
                    <th>{t("createdAt")}</th>
                    <th>{t("completedAt")}</th>
                    <th>{t("totalRows")}</th>
                    <th>{t("actions")}</th>
                  </tr>
                </thead>
              )}
              <tbody>
                {exports.length === 0 && <NoExports />}

                {exports.map((item: Export, index: number) => {
                  const dynamicTooltip = (
                    <Tooltip id={`tooltip-${index}`}>
                      <div>{item.error}</div>
                    </Tooltip>
                  );

                  return (
                    <tr key={index}>
                      <td className="text-center">
                        {item.format === "smartlead" && (
                          <img className="avatar avatar-xs" src="/assets/img/smartlead.webp" alt="Smartlead" />
                        )}
                        {item.format === "csv" && (
                          <img className="avatar avatar-xs" src="/assets/svg/components/placeholder-csv-format.svg" alt="CSV" />
                        )}
                        {item.format === "excel" && <img className="avatar avatar-xs" src="/assets/svg/brands/excel.svg" alt="Excel" />}
                        {!item.format && (
                          <img className="avatar avatar-xs" src="/assets/svg/components/placeholder-img-format.svg" alt="Unknown" />
                        )}
                      </td>
                      <td>{item.id}</td>
                      <td>
                        {item.status.toUpperCase()}{" "}
                        {item.status === "failed" && item.error && (
                          <OverlayTrigger placement="left" overlay={dynamicTooltip}>
                            <QuestionCircle size="12" />
                          </OverlayTrigger>
                        )}
                      </td>
                      <td>
                        <Moment format="DD/MM/YYYY HH:mm">{item.createdAt.toDate()}</Moment>
                      </td>
                      <td>{item.completedAt && <Moment format="DD/MM/YYYY HH:mm">{item.completedAt.toDate()}</Moment>}</td>
                      <td>{item.prospectsCount || "-"}</td>
                      <td>
                        <a
                          href={`/exports/${item.id}/download`}
                          target="_blank"
                          rel="noreferrer"
                          className={`btn btn-white btn-sm mr-2 ${
                            item.status !== "completed" || !(item.format === "csv" || item.format === "excel") ? "disabled" : ""
                          }`}
                        >
                          <Download />
                          <span className="align-middle ml-1">Descargar</span>
                        </a>
                        <button
                          className="btn btn-white btn-sm"
                          onClick={() => handleCopyDownloadUrl(item.downloadUrl)}
                          disabled={item.status !== "completed" || (item.format !== "csv" && item.format !== "excel")}
                        >
                          <Clipboard />
                          <span className="align-middle ml-1">Copiar URL</span>
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

function NoExports() {
  const { t } = useTranslation("Exports.index");
  const { analysisId } = useParams();
  return (
    <div className="card-body py-4">
      <div className="row align-items-center py-4">
        <div className="col text-center">
          <img src="/assets/svg/illustrations/oc-empty-cart.svg" alt="Empty" className="img-fluid mb-4" style={{ maxWidth: "10rem" }} />
          <p className="mb-0 text-center mb-4">{t("noExports.message")}</p>
          <Link to={`/analysis/${analysisId}`} className="btn btn-primary">
            <span className="mr-1">
              <ArrowLeft size="20" />
            </span>
            <span className="align-middle">{t("noExports.goBack")}</span>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Exports;

/**
 * Selecciona un subconjunto de propiedades de un objeto dado basado en un conjunto de claves.
 *
 * @template T - Tipo del objeto original.
 * @param {T} object - El objeto del que se seleccionarán las propiedades.
 * @param {Array<keyof T>} keys - Las claves de las propiedades a seleccionar.
 * @returns {Partial<T>} Un nuevo objeto que contiene únicamente las propiedades especificadas.
 */
export const pick = <T extends Record<string, any>>(object: T, keys: Array<keyof T>): Partial<T> => {
  return keys.reduce((obj, key) => {
    if (object && Object.prototype.hasOwnProperty.call(object, key)) {
      obj[key] = object[key];
    }
    return obj;
  }, {} as Partial<T>);
};

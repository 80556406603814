import React, { useContext, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { UserContext } from "../../contexts/UserContext";
import { useNavigate } from "react-router-dom";
import { auth } from "../../lib/firebase/firebase";
import { getMySubscription } from "../../api/mailerfind/stripe";
import Loading from "../../components/Loading";
import { generateLicense } from "../../api/mailerfind/users";
import useCheckout from "../../hooks/useCheckout";
import { useTranslation } from "react-i18next";
import usePixelTracking from "../../hooks/usePixelTracking";

function Disclaimer() {
  const navigate = useNavigate();
  const { user } = useContext(UserContext) as any;
  const { redirectToCheckout, checkoutIsLoading } = useCheckout();
  const { trackCompleteRegistration } = usePixelTracking();
  const { t } = useTranslation("Disclaimer.v2");

  const [subscription, setSubscription] = useState<any>(null);
  const [subscriptionIsLoading, setSubscriptionIsLoading] = useState(true);
  const [isLoadingLicense, setIsLoadingLicense] = useState(false);
  const [isAtBottom, setIsAtBottom] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);

  const scrollContainer = useRef(null) as any;
  let prevTouchY = 0;

  const handleAccept = async () => {
    if (!isAccepted) return toast.error(t("acceptTerms"));
    try {
      await handleGenerateLicense();
      await sendEventToPixels();
      await redirectToCheckout();
    } catch (error: any) {
      toast.error(error.message, {
        position: "bottom-center",
      });
      return;
    }
  };

  const sendEventToPixels = async () => {
    // Enviar el evento de registro completo
    trackCompleteRegistration(user.firebaseUser.uid, { method: "form" });
  };

  const handleGenerateLicense = async () => {
    try {
      setIsLoadingLicense(true);
      const jwt = await auth.currentUser!.getIdToken();
      await generateLicense(jwt);
    } finally {
      setIsLoadingLicense(false);
    }
  };

  const getSubscription = async () => {
    try {
      const jwt = await auth.currentUser!.getIdToken();
      const { subscription } = await getMySubscription(jwt);
      setSubscription(subscription);
    } finally {
      setSubscriptionIsLoading(false);
    }
  };

  useEffect(() => {
    if (subscriptionIsLoading || !user.firebaseUser) return;
    if (subscription?.status === "active") navigate("/");
  }, [user, subscription, subscriptionIsLoading]);

  useEffect(() => {
    if (!user.firebaseUser) return navigate("/login");
    getSubscription();
  }, [user.firebaseUser]);

  useEffect(() => {
    if (!user.firebaseUser || subscriptionIsLoading) return;

    const handleWheel = (event: any) => {
      event.preventDefault();
      scrollContainer.current.scrollTop += event.deltaY * 10;
      if (scrollContainer.current.scrollTop + scrollContainer.current.clientHeight + 1000 >= scrollContainer.current.scrollHeight) {
        setIsAtBottom(true);
      }
    };

    const handleTouchStart = (event: any) => {
      prevTouchY = event.touches[0].clientY;
    };

    const handleTouchEnd = (event: any) => {
      const newTouchY = event.changedTouches[0].clientY;
      scrollContainer.current.scrollTop -= newTouchY - prevTouchY;
      if (scrollContainer.current.scrollTop + scrollContainer.current.clientHeight + 1000 >= scrollContainer.current.scrollHeight) {
        setIsAtBottom(true);
      }
    };

    const handleScrollEnd = () => {
      if (scrollContainer.current.scrollTop + scrollContainer.current.clientHeight + 1000 >= scrollContainer.current.scrollHeight) {
        setIsAtBottom(true);
      }
    };

    const handleScroll = () => {
      handleScrollEnd();
    };

    scrollContainer.current.addEventListener("wheel", handleWheel);
    scrollContainer.current.addEventListener("touchstart", handleTouchStart);
    scrollContainer.current.addEventListener("touchend", handleTouchEnd);
    scrollContainer.current.addEventListener("scroll", handleScroll);

    return () => {
      if (!scrollContainer.current) return;
      scrollContainer.current.removeEventListener("wheel", handleWheel);
      scrollContainer.current.removeEventListener("touchstart", handleTouchStart);
      scrollContainer.current.removeEventListener("touchend", handleTouchEnd);
      scrollContainer.current.removeEventListener("scroll", handleScroll);
    };
  }, [user, subscriptionIsLoading]);

  const handleCheckboxChange = (event: any) => {
    if (!isAtBottom) {
      let count = 0;
      const step = () => {
        if (count <= 50) {
          scrollContainer.current.scrollTop = scrollContainer.current.scrollTop + 1;
        } else {
          scrollContainer.current.scrollTop = scrollContainer.current.scrollTop - 1;
        }
        count++;
        if (count <= 100) {
          window.requestAnimationFrame(step);
        }
      };
      window.requestAnimationFrame(step);
      toast.error(t("mustReadToEnd"));
    } else {
      if (event.target.checked) {
        setIsAccepted(true);
      } else {
        setIsAccepted(false);
      }
    }
  };

  if (!user.firebaseUser || subscriptionIsLoading) return <Loading />;

  return (
    <div className="bg-light vh-100 d-flex flex-column justify-content-center align-items-center">
      <div className="mx-auto text-center mb-3" style={{ maxWidth: "500px" }}>
        <h1>{t("title")}</h1>
        <p>{t("acceptConditions")}</p>
      </div>

      <div className="card mx-auto" style={{ maxWidth: "37rem" }}>
        <div className="card-header py-4 px-6">
          <h2 className="text-primary h5 m-0 p-0">{t("contractConditions")}</h2>
        </div>
        <div className="card-body p-6" style={{ height: "20rem", overflowY: "scroll" }} ref={scrollContainer}>
          <section>
            <h2 className="text-primary h5">{t("providerInfo")}</h2>
            <p>
              Titular: Pedro García-Mauriño Roca
              <br />
              NRT: F-414402-R
              <br />
              Domicilio fiscal: Avinguda Príncep Benlloch, núm. 66, Planta 3, Porta 3, (AD500) Andorra la Vella, Principat d’Andorra.
              <br />
              Teléfono: +376 387 347
              <br />
              E-mail: pedro.gmr@mailerfind.com
              <br />
              Delegado de protección de datos: dpd@gamalex.es
            </p>
          </section>
          <section className="text-justify">
            <h2 className="text-primary h5">{t("termsConditions")}</h2>
            <h3 className="h6">2.1. Determinación del objeto del contrato</h3>
            <p>
              Mediante el presente documento se establecen las condiciones de contratación que regularán la entrega por parte de “EL
              TITULAR” a los usuarios del sitio web de los programas ofrecidos a través del mismo, que se compone de la página web ubicada
              en la URL https://mailerfind.com.
            </p>
            <p>Estas Condiciones de contratación regulan la adquisición de los usuarios de un software de búsqueda de datos de contacto.</p>
            <p>
              No es por tanto objeto de regulación por estas Condiciones de contratación las cuestiones jurídicas que puedan derivarse en
              cuanto al contenido de la información y al cumplimiento de la normativa vigente.
            </p>
            <p>
              No obstante, por razones de transparencia y para facilitar una mejor información en los usuarios, de modo que puedan emitir un
              consentimiento contractual previamente y debidamente informado, se incluyen en estas Condiciones de contratación la referencia
              a concretas cuestiones reguladas en la normativa apuntada, a los meros efectos de permitir al usuario tener un mejor
              conocimiento de la información necesaria para la contratación del producto.
            </p>
            <p>
              Asimismo, “EL TITULAR” con la finalidad de mantener el marco contractual lo más actualizado posible podrá llevar a cabo
              modificaciones en las presentes Condiciones de contratación, de las que se informará previamente al usuario para su aceptación
              o rechazo en caso de resultar sustanciales. En cualquier caso, se considerará que el usuario acepta expresamente dichas
              modificaciones o actualizaciones si vuelve a contratar los servicios ofrecidos por “EL TITULAR” en el Sitio web una vez
              adaptadas las nuevas Condiciones de contratación.
            </p>
            <p>
              En caso de ser aceptadas por el usuario, las nuevas condiciones de contratación sustituirán plenamente a las condiciones
              vigentes hasta ese momento, comenzando a surtir efecto en las nuevas suscripciones contratadas por el usuario a partir de la
              fecha de aceptación de las citadas nuevas condiciones.
            </p>
            <p>
              La marcación de la casilla correspondiente en el proceso de contratación, así como el hecho de seguir telemáticamente todos
              los pasos establecidos para el mismo, supone la aceptación expresa de las presentes Condiciones de contratación por parte del
              usuario, teniendo la misma validez que su firma presencial. De este modo, el usuario reconoce ser una persona con capacidad
              suficiente para adquirir las obligaciones derivadas de sus actuaciones a través del Sitio web, que ha leído previamente y
              comprende su contenido.
            </p>
            <p>
              Caso de no ser aceptadas las nuevas Condiciones de contratación se entenderá extinguida desde dicho momento la relación
              contractual entre ambas partes, sin perjuicio del cumplimiento de las obligaciones pendientes, en especial las de contenido
              económico derivadas de los suministros de software previamente realizados.
            </p>
            <p>
              Todo el proceso de contratación se realizará a través de la plataforma electrónica desarrollada por el “EL TITULAR” en español
              o en inglés, en función de la elección del usuario.
            </p>
            <h3 className="h6">2.2. Glosario de términos</h3>
            <p>
              <b>“EL TITULAR”:</b> Pedro García-Mauriño Roca
            </p>
            <p>
              <b>Sitio web:</b> Página web ubicada en la URL https://mailerfind.com a través de la cual se pueden contratar los servicios.
            </p>
            <p>
              <b>Usuario:</b> Toda persona física o jurídica que solicite alguno de los servicios ofrecidos por el “EL TITULAR” a través del
              Sitio web.
            </p>
            <p>
              <b>Área privada:</b> Zona del Sitio web de acceso restringido a usuarios registrados al objeto de optimizar la gestión de los
              servicios.
            </p>
            <p>
              <b>Cuenta virtual:</b> Sistema de pago de los servicios especialmente habilitado para usuarios registrados.
            </p>
            <p>
              <b>Mecanismo de identificación:</b> permite el acceso al área privada de los usuarios, bien mediante un certificado digital,
              bien a través del uso de claves (nombre de usuario y contraseña) que les serán facilitadas por el usuario registrado para este
              fin, cumpliendo con los requisitos de robustez y seguridad establecidas por el “EL TITULAR”.
            </p>
            <p>
              <b>Aviso legal:</b> Cláusula que delimita el alcance, condiciones y efectos bajo los cuales se produce la contratación de los
              servicios de intermediación del “EL TITULAR”.
            </p>
            <p>
              <b>Condiciones de contratación:</b> Conjunto de cláusulas que integran el presente documento, que definen el contenido de la
              prestación de los servicios, así como los derechos y obligaciones de las partes contratantes.
            </p>
            <p>
              <b>Servicios:</b> Los servicios de información de software que pueden ser también utilizados a través del cauce seguro que se
              determina en las presentes condiciones de contratación.
            </p>
            <p>
              <b>Certificado digital:</b> Certificado de firma admitido por la normativa aplicable.
            </p>
          </section>
          <section className="text-justify">
            <h2 className="text-primary h5">3. Definición de los servicios de software</h2>
            <h3 className="h6">3.1. Servicios de Información de Software</h3>
            <p>Los usuarios podrán obtener, previo pago de los honorarios establecidos, acceso a los diferentes Servicios de Software:</p>
          </section>
          <section className="text-justify">
            <h2 className="text-primary h5">4. Procedimiento de contratación de los servicios</h2>
            <p>
              El procedimiento de contratación de los Servicios se lleva a cabo de forma completamente electrónica a través del Sitio web.
              Los Servicios podrán solicitarse durante las veinticuatro (24) horas del día, todos los días de la semana. Queda a salvo el
              derecho a interrumpir los Servicios por necesidades de mantenimiento del sistema o por cualquier otra causa que obligue a
              ello.
            </p>
            <p>
              El solicitante habrá de cumplimentar un formulario electrónico disponible en el Sitio web que contiene los campos necesarios
              para su identificación y en su caso acreditar la representación, además de los datos necesarios exigidos conforme a la
              normativa fiscal y de facturación.
            </p>
            <p>
              La identificación de los usuarios podrá realizarse cumplimentando los datos requeridos en el formulario habilitado a tal
              efecto.
            </p>
            <h3 className="h6">4.1. Usuarios que pueden tener acceso a los Servicios</h3>
            <p>
              Podrán ser usuarios y, por tanto, solicitar los Servicios las personas físicas y jurídicas debidamente identificadas. No
              obstante, la solicitud de los Servicios deberá ser siempre realizada por una persona física, mayor de edad y con plena
              capacidad para contratar en nombre propio o de la persona jurídica en cuyo nombre realiza la solicitud, siendo el solicitante
              responsable de la veracidad de los datos aportados a tal efecto en el formulario para el inicio del procedimiento.
            </p>
            <h3 className="h6">4.2. Uso de la información y protección de datos de carácter personal</h3>
            <p>
              El peticionario se compromete, de acuerdo con la legislación de datos de carácter personal, a no destinar la información a un
              uso distinto del legalmente permitido y a no incorporar su contenido a ninguna base de datos para uso comercial o tratamiento
              automatizado de la información.
            </p>
            <p>
              La información puesta a disposición del usuario es para su uso exclusivo y tiene el carácter de intransferible. Queda
              absolutamente prohibida la retransmisión o cesión, incluso gratuita, de la información por el usuario a cualquier otra
              persona.
            </p>
            <p>
              Queda totalmente prohibida la incorporación de los datos que se contienen en este documento a bases o ficheros informatizados
              que puedan ser susceptibles de consulta individualizada por personas físicas o jurídicas, y ello, aunque se exprese la
              procedencia de la información. El usuario del software que incumpla lo dispuesto en este apartado exime expresamente a “El
              Titular” de cualquier responsabilidad que por tales hechos pudiere derivarse habida cuenta de que ha prestado su
              consentimiento previo e informado a las presentes condiciones generales de la contratación.
            </p>
            <p>
              Como se ha indicado, el incumplimiento de estas obligaciones podrá a su vez dar lugar a las responsabilidades que en materia
              de protección de datos de carácter personal se determinen de acuerdo con la legislación europea y nacional en dicha materia
              vigente en cada momento.
            </p>
            <p>
              Toda la información relativa a protección de datos de carácter personal se encuentra disponible en el punto 9 del presente
              contrato y en el anexo correspondiente.
            </p>
          </section>
          <section className="text-justify">
            <h2 className="text-primary h5">5. Condiciones económicas y fiscalidad</h2>
            <h3 className="h6">5.1. Costes de los Servicios de Software</h3>
            <h4 className="h6">5.1.1. Software de información de mercado</h4>
            <p>
              El coste de los Servicios de información de mercado es libremente establecidos por “El Titular”, pudiendo sufrir variaciones
              en cualquier momento y sin que por ello haya causa de indemnización y/o desistimiento.
            </p>
            <h3 className="h6">5.2. Formas de pago de los Servicios de Software</h3>
            <p>
              “EL TITULAR” procederá al cobro de los servicios con carácter inmediato a su validación por el usuario esporádico, cuyo pago
              podrá realizarse empleando alguna de las siguientes formas de pago:
            </p>
            <ul>
              <li>
                <p>
                  Tarjetas de crédito/débito: las tarjetas de crédito/débito aceptadas son todas aquéllas que se apoyan en el circuito Visa,
                  MasterCard o American Express;
                </p>
              </li>
            </ul>
            <h3 className="h6">5.3. Impuestos aplicables</h3>
            <p>
              Todas las cantidades devengadas habrán de ser incrementadas con el importe de los impuestos devengados por los servicios
              prestados en la forma que se detalla a continuación.
            </p>
            <h4 className="h6">5.3.1 Destinatario particular</h4>
            <p>
              Todas las operaciones estarán sujetos a IVA pues “El Titular” tiene su domicilio fiscal en la Península, con independencia de
              la localización del destinatario.
            </p>
            <h4 className="h6">5.3.2 Destinatario empresario o profesional que actúe como tal</h4>
            <p>
              Será de aplicación lo dispuesto en el apartado 5.3.1 por lo que tributarán por el IVA con independencia de la localización del
              destinatario.
            </p>
            <h4 className="h6">5.3.3 Destinatario empresario o profesional intracomunitario que actúe como tal</h4>
            <p>
              Los destinatarios empresarios/profesionales intracomunitarios con número de identificación individual a efectos del IVA (VIES)
              quedarán exonerados de soportar el IVA, una vez que se haya realizado la comprobación oportuna contra el webservice de la
              Comisión Europea, y el mismo se haya validado correctamente.
            </p>
            <p>
              No obstante, si la validación de VIES en el webservice de la Comisión Europea no es correcta, y el destinatario puede
              acreditar que tiene en su país el carácter de empresario o profesional, podrá solicitar la devolución del impuesto cargado,
              mediante la oportuna comunicación vía correo electrónico dirigida a “El Titular” en la que manifieste y acredite su condición
              de tal.
            </p>
            <h4 className="h6">5.3.4 Destinatario empresario o profesional extracomunitario que actúe como tal</h4>
            <p>
              Los destinatarios empresarios/profesionales extracomunitarios estarán exonerados del IVA siempre que puedan acreditar que
              tienen en su país el carácter de empresario o profesional. A tal fin podrán solicitar la devolución del impuesto cargado,
              mediante la oportuna comunicación vía correo electrónico dirigida a “El Titular” en la que manifieste y acredite su condición
              de tal
            </p>
            <h4 className="h6">5.3.5 Cuestiones comunes</h4>
            <p>
              El Usuario deberá informar sobre la condición en la que actúa, a efectos del IVA, y si está obligado a practicar retención a
              efectos del IRPF:
            </p>
            <p>A) A efectos del IVA deberá indicar:</p>
            <ul>
              <li>
                <p>
                  Si está establecido en España sus datos personales: país de residencia, nombre y apellidos o denominación social,
                  documento de identificación, dirección y dirección de correo electrónico;
                </p>
              </li>
              <li>
                <p>
                  Si no está establecido en España, el solicitante deberá indicar si es o no un operador intracomunitario con número de
                  identificación individual a efectos de IVA (VIES). En caso de responder afirmativamente, deberá introducir sus datos
                  personales y de contacto y su número de VIES;
                </p>
              </li>
              <li>
                <p>
                  Si no cuenta con VIES, deberá indicar sus datos personales y de contacto. Si, pese a carecer de VIES, puede acreditar que
                  tiene en su país el carácter de empresario o profesional, podrá solicitar la devolución del impuesto cargado, mediante
                  escrito en el que manifieste y acredite su condición de tal. Este régimen se aplicará igualmente al empresario o
                  profesional extracomunitario.
                </p>
              </li>
            </ul>
            <p>B) A efectos del IRPF:</p>
            <p>
              El pagador de la factura, en los casos previstos en el artículo 99 de la Ley 35/2006 de 27 de noviembre del IRPF, está
              obligado a practicar la retención del IRPF y a ingresar su importe en el Tesoro.
            </p>
            <p>
              Están obligados a retener las personas jurídicas, las entidades (incluidas las entidades en régimen de atribución de rentas),
              y las personas físicas que ejerzan actividades económicas y satisfagan la factura en el ejercicio de su actividad.
            </p>
            <p>
              Tiene también dichas obligaciones el pagador no residente (persona física, jurídica y demás entidades) que opere en territorio
              español mediante establecimiento permanente y aquel, sin establecimiento permanente, para quien la satisfacción de la factura
              constituya gasto deducible en el impuesto sobre la renta de no residentes.
            </p>
            <p>
              Están obligados a practicar la retención, quienes reúnan las condiciones antes citadas, sin que exonere de esta obligación
              haber negado dicho carácter al rellenar el formulario de petición.
            </p>
            <p>
              Los obligados a retener deben también cumplir las obligaciones formales que establece el artículo 108 del Reglamento del IRPF
              (RD 439/2007 de 4 de marzo), incluida la de expedir en su momento al emisor de la factura la certificación acreditativa de la
              retención.
            </p>
            <p>
              La decisión de no practicar la retención, ingresando su importe en el Tesoro Público y cumpliendo con todas las obligaciones
              formales derivadas de la misma, es imputable al pagador que esté obligado a hacerlo.
            </p>
            <h3 className="h6">5.5. Pagos realizados por tarjeta bancaria</h3>
            <p>
              El usuario esporádico podrá abonar los Servicios mediante tarjeta bancaria, por medio de una pasarela de pagos ofrecida por
              Stripe, de acuerdo con los términos y condiciones que establezcan la misma.
            </p>
            <p>
              En ningún momento del procedimiento de compra “EL TITULAR” conocerá la información relativa a los datos de pago, que son
              digitalizadas directamente en la pasarela de pago (con sistemas de seguridad que evitan la interceptación, modificación o
              falsificación de la información). Ningún archivo informático de “EL TITULAR” contiene, ni conserva, tales datos. En ningún
              caso “EL TITULAR” puede ser, por lo tanto, considerado responsable por un eventual uso fraudulento e indebido de estos datos
              de pago por parte de terceros.
            </p>
            <p>
              En todo caso, el abono de cantidades económicas a través de Internet será realizado por medio de la plataforma provista por
              parte de una entidad financiera externa, que se encontrará alojada en un sitio web bajo protocolo seguro SSL y que utilizará
              sistemas de autenticación acordes a la regulación de pagos europea PSD2.
            </p>
            <h3 className="h6">5.6. Abono y facturación</h3>
            <p>
              Asimismo, la contratación solo será efectiva cuando “EL TITULAR” reciba la confirmación del pago. Si la transacción resultase
              por algún motivo denegada por dicha entidad o no se aportase la cantidad íntegra correspondiente al importe de los Servicios,
              la contratación se suspenderá, informando al usuario de que la transacción no ha sido finalizada.
            </p>
            <p>
              Cuando el usuario haya contratado los Servicios y registrado el pago correspondiente a través de cualquiera de las modalidades
              de pago puestas a disposición de los usuarios por “EL TITULAR”, tendrá la posibilidad de descargar, desde un enlace habilitado
              al efecto, la información relativa a la contratación realizada, siempre y cuando no se hayan denegado los Servicios.
            </p>
            <p>
              Las facturas serán expedidas en formato electrónico, con los requisitos contenidos en el RD 1619/2012, de 30 de noviembre, por
              “EL TITULAR”. Si el usuario desea recibir la factura en otro medio deberá ponerse en contacto con “El Titular”.{" "}
            </p>
            <p>
              Al destinatario de la factura le incumbe, en su caso, la obligación de retención prevista en el artículo 99.2 de la Ley
              35/2006.
            </p>
            <p>La falta de pago de cualquier factura dará lugar a que se deniegue el Servicio solicitado.</p>
            <h3 className="h6">5.7. Datos de facturación</h3>
            <p>
              El usuario será responsable de facilitar a “EL TITULAR” todos los datos necesarios para la correcta facturación de los
              servicios, que deberán ser veraces, actuales y ajustados a la realidad, especialmente en lo relativo a formas de pago.
              Asimismo, el usuario será responsable, de los daños y perjuicios que se puedan ocasionar a “EL TITULAR” o a terceros por los
              datos aportados, como consecuencia de la utilización de datos no actualizados, falsos o que no se corresponden con la realidad
              y por la utilización por parte de terceros de las claves personales del usuario, especialmente respecto de aquellos datos
              facilitados para la facturación.
            </p>
            <p>
              El usuario podrá comunicar la actualización de sus datos por medio de los cauces identificados en el apartado de Atención al
              Usuario.
            </p>
            <p>
              En caso de contratar en nombre de una persona jurídica, el usuario responderá de la realidad de su capacidad para contratar en
              nombre de dicha persona jurídica, respondiendo subsidiariamente de cualquier impago derivado de la falta de veracidad en los
              mismos. Por su parte, la persona jurídica representada, una vez aceptado el primer pago derivado de la contratación, no podrá
              oponerse a las posteriores facturaciones por la falta de autorización o el defecto o revocación de poderes de la persona
              física contratante.
            </p>
          </section>
          <section className="text-justify">
            <h2 className="text-primary h5">6. Condiciones de uso del SBDC</h2>
            <p>
              El usuario sólo tendrá acceso a la información después de finalizado el proceso de solicitud y una vez se haya formalizado el
              pago.
            </p>
            <p>
              El contenido de la información facilitada por medio del software de información es para uso particular y exclusivo del usuario
              que los solicita, exclusivamente para los fines que le son propios y en virtud del interés legítimo alegado, no pudiendo
              realizarse un uso comercial del mismo, por lo que el usuario en ningún caso podrá modificar, adaptar, comunicar, poner a
              disposición, divulgar o comercializar dicha información, total o parcialmente, ni a título oneroso ni gratuito (fuera de los
              casos justificados por el interés legítimo alegado), para ninguna finalidad distinta de la descrita. En concreto, en
              cumplimiento de la normativa vigente, el usuario se compromete a no incorporar dicha información a bases de datos o ficheros
              informatizados que puedan ser susceptibles de consulta individualizada por personas físicas o jurídicas diferentes del
              usuario.
            </p>
            <p>
              La aplicación de las normas sobre protección de datos de carácter personal y la necesidad de vigilancia de la integridad de
              los archivos informáticos, impidiendo la copia global o "tele vaciado" de los mismos, puede hacer necesaria, en determinados
              casos, la limitación de solicitudes masivas de información procedentes de un mismo usuario.
            </p>
            <p>
              El usuario del software de información, por el mero hecho adquirirlo y de aplicarlo, acepta y se compromete a utilizar los
              datos personales obtenidos conforme a las normas y con las limitaciones que establece la legislación de protección de datos
              personales, esto es, el Reglamento (UE) 2016/ 679 del parlamento europeo y del consejo de 27 de abril de 2016 relativo a la
              protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos
              datos; así como la normativa nacional aplicable y vigente en cada momento en esta materia.
            </p>
          </section>
          <section className="text-justify">
            <h2 className="text-primary h5">7. Derecho de desistimiento</h2>
            <p>
              De conformidad con lo establecido en el RDL 1/2007, de 16 de noviembre, por el que se aprueba el texto refundido de la Ley
              General para la Defensa de los Consumidores y Usuarios, el usuario tendrá derecho a ejercer el desistimiento en el plazo de 14
              días naturales desde la solicitud de los Servicios.
            </p>
            <p>
              Por tanto, el usuario acepta y da su consentimiento a que, en el caso de que el desistimiento se produzca una vez que la
              prestación del Servicio de información de software haya sido completamente ejecutada, habrá perdido su derecho de
              desistimiento.
            </p>
            <p>
              En caso de que el usuario ejercite en el plazo y forma su derecho de desistimiento, “EL TITULAR” reembolsará al usuario los
              pagos efectuados en el plazo de 14 días naturales a partir de la fecha en la que se nos informe de su decisión de desistir del
              Servicio solicitado. Dicho reembolso se efectuará utilizando el mismo medio empleado por el Usuario para el pago de los
              Servicios. En caso de que no sea posible devolver el dinero por el mismo medio empleado por causas ajenas a “EL TITULAR”, se
              buscará la opción más adecuada para la devolución, quedando entre tanto no prescriba la acción en concepto de depósito a
              disposición del solicitante.
            </p>
            <p>
              A todos los efectos, no tendrán la consideración de consumidor o usuario las personas físicas o jurídicas, incluyéndose las
              entidades sin personalidad jurídica que actúen sin ánimo de lucro, que soliciten los Servicios con motivo de un propósito
              relacionado con su actividad comercial, empresarial, oficio o profesión.
            </p>
          </section>
          <section className="text-justify">
            <h2 className="text-primary h5">8. Información básica sobre protección de datos en la utilización del software</h2>
            <p>
              A los efectos del cumplimiento del Reglamento General de Protección de Datos 2016/679 del Parlamento Europeo y del Consejo, de
              27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y
              a la libre circulación de estos datos, y a la Ley Orgánica 3/2018 de 5 de Diciembre de Protección de Datos Personales y
              Garantía de los Derechos Digitales, el usuario se obliga a no cometer con el software adquirido ninguna de las infracciones
              que se detallan a continuación y que, para el caso de cometerlas, exime expresamente a ”El Titular” de cualquier tipo de
              responsabilidad.
            </p>
            <p>Infracciones consideradas muy graves:</p>
            <p>
              1. En función de lo que establece el artículo 83.5 del Reglamento (UE) 2016/679 se consideran muy graves y prescribirán a los
              tres años las infracciones que supongan una vulneración sustancial de los artículos mencionados en aquel y, en particular, las
              siguientes:
            </p>
            <p>
              a) El tratamiento de datos personales vulnerando los principios y garantías establecidos en el artículo 5 del Reglamento (UE)
              2016/679.
            </p>
            <p>
              b) El tratamiento de datos personales sin que concurra alguna de las condiciones de licitud del tratamiento establecidas en el
              artículo 6 del Reglamento (UE) 2016/679.
            </p>
            <p>
              c) El incumplimiento de los requisitos exigidos por el artículo 7 del Reglamento (UE) 2016/679 para la validez del
              consentimiento.
            </p>
            <p>
              d) La utilización de los datos para una finalidad que no sea compatible con la finalidad para la cual fueron recogidos, sin
              contar con el consentimiento del afectado o con una base legal para ello.
            </p>
            <p>
              e) El tratamiento de datos personales de las categorías a las que se refiere el artículo 9 del Reglamento (UE) 2016/679, sin
              que concurra alguna de las circunstancias previstas en dicho precepto y en el artículo 9 de esta ley orgánica.
            </p>
            <p>
              f) El tratamiento de datos personales relativos a condenas e infracciones penales o medidas de seguridad conexas fuera de los
              supuestos permitidos por el artículo 10 del Reglamento (UE) 2016/679 y en el artículo 10 de esta ley orgánica.
            </p>
            <p>
              g) El tratamiento de datos personales relacionados con infracciones y sanciones administrativas fuera de los supuestos
              permitidos por el artículo 27 de esta ley orgánica.
            </p>
            <p>
              h) La omisión del deber de informar al afectado acerca del tratamiento de sus datos personales conforme a lo dispuesto en los
              artículos 13 y 14 del Reglamento (UE) 2016/679 y 12 de esta ley orgánica.
            </p>
            <p>i) La vulneración del deber de confidencialidad establecido en el artículo 5 de esta ley orgánica.</p>
            <p>
              j) La exigencia del pago de un canon para facilitar al afectado la información a la que se refieren los artículos 13 y 14 del
              Reglamento (UE) 2016/679 o por atender las solicitudes de ejercicio de derechos de los afectados previstos en los artículos 15
              a 22 del Reglamento (UE) 2016/679, fuera de los supuestos establecidos en su artículo 12.5.
            </p>
            <p>
              k) El impedimento o la obstaculización o la no atención reiterada del ejercicio de los derechos establecidos en los artículos
              15 a 22 del Reglamento (UE) 2016/679.
            </p>
            <p>
              l) La transferencia internacional de datos personales a un destinatario que se encuentre en un tercer país o a una
              organización internacional, cuando no concurran las garantías, requisitos o excepciones establecidos en los artículos 44 a 49
              del Reglamento (UE) 2016/679.
            </p>
            <p>
              m) El incumplimiento de las resoluciones dictadas por la autoridad de protección de datos competente en ejercicio de los
              poderes que le confiere el artículo 58.2 del Reglamento (UE) 2016/679.
            </p>
            <p>
              n) El incumplimiento de la obligación de bloqueo de los datos establecida en el artículo 32 de esta ley orgánica cuando la
              misma sea exigible.
            </p>
            <p>
              ñ) No facilitar el acceso del personal de la autoridad de protección de datos competente a los datos personales, información,
              locales, equipos y medios de tratamiento que sean requeridos por la autoridad de protección de datos para el ejercicio de sus
              poderes de investigación.
            </p>
            <p>
              o) La resistencia u obstrucción del ejercicio de la función inspectora por la autoridad de protección de datos competente.
            </p>
            <p>p) La reversión deliberada de un procedimiento de anonimización a fin de permitir la reidentificación de los afectados.</p>
            <p>
              2. Tendrán la misma consideración y también prescribirán a los tres años las infracciones a las que se refiere el artículo
              83.6 del Reglamento (UE) 2016/679.
            </p>
            <p>Infracciones consideradas graves:</p>
            <p>
              En función de lo que establece el artículo 83.4 del Reglamento (UE) 2016/679 se consideran graves y prescribirán a los dos
              años las infracciones que supongan una vulneración sustancial de los artículos mencionados en aquel y, en particular, las
              siguientes:
            </p>
            <p>
              a) El tratamiento de datos personales de un menor de edad sin recabar su consentimiento, cuando tenga capacidad para ello, o
              el del titular de su patria potestad o tutela, conforme al artículo 8 del Reglamento (UE) 2016/679.
            </p>
            <p>
              b) No acreditar la realización de esfuerzos razonables para verificar la validez del consentimiento prestado por un menor de
              edad o por el titular de su patria potestad o tutela sobre el mismo, conforme a lo requerido por el artículo 8.2 del
              Reglamento (UE) 2016/679.
            </p>
            <p>
              c) El impedimento o la obstaculización o la no atención reiterada de los derechos de acceso, rectificación, supresión,
              limitación del tratamiento o a la portabilidad de los datos en tratamientos en los que no se requiere la identificación del
              afectado, cuando este, para el ejercicio de esos derechos, haya facilitado información adicional que permita su
              identificación.
            </p>
            <p>
              d) La falta de adopción de aquellas medidas técnicas y organizativas que resulten apropiadas para aplicar de forma efectiva
              los principios de protección de datos desde el diseño, así como la no integración de las garantías necesarias en el
              tratamiento, en los términos exigidos por el artículo 25 del Reglamento (UE) 2016/679.
            </p>
            <p>
              e) La falta de adopción de las medidas técnicas y organizativas apropiadas para garantizar que, por defecto, solo se tratarán
              los datos personales necesarios para cada uno de los fines específicos del tratamiento, conforme a lo exigido por el artículo
              25.2 del Reglamento (UE) 2016/679.
            </p>
            <p>
              f) La falta de adopción de aquellas medidas técnicas y organizativas que resulten apropiadas para garantizar un nivel de
              seguridad adecuado al riesgo del tratamiento, en los términos exigidos por el artículo 32.1 del Reglamento (UE) 2016/679.
            </p>
            <p>
              g) El quebrantamiento, como consecuencia de la falta de la debida diligencia, de las medidas técnicas y organizativas que se
              hubiesen implantado conforme a lo exigido por el artículo 32.1 del Reglamento (UE) 2016/679.
            </p>
            <p>
              h) El incumplimiento de la obligación de designar un representante del responsable o encargado del tratamiento no establecido
              en el territorio de la Unión Europea, conforme a lo previsto en el artículo 27 del Reglamento (UE) 2016/679.
            </p>
            <p>
              i) La falta de atención por el representante en la Unión del responsable o del encargado del tratamiento de las solicitudes
              efectuadas por la autoridad de protección de datos o por los afectados.
            </p>
            <p>
              j) La contratación por el responsable del tratamiento de un encargado de tratamiento que no ofrezca las garantías suficientes
              para aplicar las medidas técnicas y organizativas apropiadas conforme a lo establecido en el Capítulo IV del Reglamento (UE)
              2016/679.
            </p>
            <p>
              k) Encargar el tratamiento de datos a un tercero sin la previa formalización de un contrato u otro acto jurídico escrito con
              el contenido exigido por el artículo 28.3 del Reglamento (UE) 2016/679.
            </p>
            <p>
              l) La contratación por un encargado del tratamiento de otros encargados sin contar con la autorización previa del responsable,
              o sin haberle informado sobre los cambios producidos en la subcontratación cuando fueran legalmente exigibles.
            </p>
            <p>
              m) La infracción por un encargado del tratamiento de lo dispuesto en el Reglamento (UE) 2016/679 y en la presente ley
              orgánica, al determinar los fines y los medios del tratamiento, conforme a lo dispuesto en el artículo 28.10 del citado
              reglamento.
            </p>
            <p>n) No disponer del registro de actividades de tratamiento establecido en el artículo 30 del Reglamento (UE) 2016/679.</p>
            <p>
              ñ) No poner a disposición de la autoridad de protección de datos que lo haya solicitado, el registro de actividades de
              tratamiento, conforme al apartado 4 del artículo 30 del Reglamento (UE) 2016/679.
            </p>
            <p>
              o) No cooperar con las autoridades de control en el desempeño de sus funciones en los supuestos no previstos en el artículo 72
              de esta ley orgánica.
            </p>
            <p>
              p) El tratamiento de datos personales sin llevar a cabo una previa valoración de los elementos mencionados en el artículo 28
              de esta ley orgánica.
            </p>
            <p>
              q) El incumplimiento del deber del encargado del tratamiento de notificar al responsable del tratamiento las violaciones de
              seguridad de las que tuviera conocimiento.
            </p>
            <p>
              r) El incumplimiento del deber de notificación a la autoridad de protección de datos de una violación de seguridad de los
              datos personales de conformidad con lo previsto en el artículo 33 del Reglamento (UE) 2016/679.
            </p>
            <p>
              s) El incumplimiento del deber de comunicación al afectado de una violación de la seguridad de los datos de conformidad con lo
              previsto en el artículo 34 del Reglamento(UE) 2016/679 si el responsable del tratamiento hubiera sido requerido por la
              autoridad de protección de datos para llevar a cabo dicha notificación.
            </p>
            <p>
              t) El tratamiento de datos personales sin haber llevado a cabo la evaluación del impacto de las operaciones de tratamiento en
              la protección de datos personales en los supuestos en que la misma sea exigible.
            </p>
            <p>
              u) El tratamiento de datos personales sin haber consultado previamente a la autoridad de protección de datos en los casos en
              que dicha consulta resulta preceptiva conforme al artículo 36 del Reglamento (UE) 2016/679 o cuando la ley establezca la
              obligación de llevar a cabo esa consulta.
            </p>
            <p>
              v) El incumplimiento de la obligación de designar un delegado de protección de datos cuando sea exigible su nombramiento de
              acuerdo con el artículo 37 del Reglamento (UE) 2016/679 y el artículo 34 de esta ley orgánica.
            </p>
            <p>
              w) No posibilitar la efectiva participación del delegado de protección de datos en todas las cuestiones relativas a la
              protección de datos personales, no respaldarlo o interferir en el desempeño de sus funciones.
            </p>
            <p>
              x) La utilización de un sello o certificación en materia de protección de datos que no haya sido otorgado por una entidad de
              certificación debidamente acreditada o en caso de que la vigencia del mismo hubiera expirado.
            </p>
            <p>
              y) Obtener la acreditación como organismo de certificación presentando información inexacta sobre el cumplimiento de los
              requisitos exigidos por el artículo 43 del Reglamento (UE) 2016/679.
            </p>
            <p>
              z) El desempeño de funciones que el Reglamento (UE) 2016/679 reserva a los organismos de certificación, sin haber sido
              debidamente acreditado conforme a lo establecido en el artículo 39 de esta ley orgánica.
            </p>
            <p>
              aa) El incumplimiento por parte de un organismo de certificación de los principios y deberes a los que está sometido según lo
              previsto en los artículos 42 y 43 de Reglamento (UE) 2016/679.
            </p>
            <p>
              ab) El desempeño de funciones que el artículo 41 del Reglamento (UE) 2016/679 reserva a los organismos de supervisión de
              códigos de conducta sin haber sido previamente acreditado por la autoridad de protección de datos competente.
            </p>
            <p>
              ac) La falta de adopción por parte de los organismos acreditados de supervisión de un código de conducta de las medidas que
              resulten oportunas en caso que se hubiera producido una infracción del código, conforme exige el artículo 41.4 del Reglamento
              (UE) 2016/679.
            </p>
            <p>Infracciones consideradas leves:</p>
            <p>
              Se consideran leves y prescribirán al año las restantes infracciones de carácter meramente formal de los artículos mencionados
              en los apartados 4 y 5 del artículo 83 del Reglamento (UE) 2016/679 y, en particular, las siguientes:
            </p>
            <p>
              a) El incumplimiento del principio de transparencia de la información o el derecho de información del afectado por no
              facilitar toda la información exigida por los artículos 13 y 14 del Reglamento (UE) 2016/679.
            </p>
            <p>
              b) La exigencia del pago de un canon para facilitar al afectado la información exigida por los artículos 13 y 14 del
              Reglamento (UE) 2016/679 o por atender las solicitudes de ejercicio de derechos de los afectados previstos en los artículos 15
              a 22 del Reglamento (UE) 2016/679, cuando así lo permita su artículo 12.5, si su cuantía excediese el importe de los costes
              afrontados para facilitar la información o realizar la actuación solicitada.
            </p>
            <p>
              c) No atender las solicitudes de ejercicio de los derechos establecidos en los artículos 15 a 22 del Reglamento (UE) 2016/679,
              salvo que resultase de aplicación lo dispuesto en el artículo 72.1.k) de esta ley orgánica.
            </p>
            <p>
              d) No atender los derechos de acceso, rectificación, supresión, limitación del tratamiento o a la portabilidad de los datos en
              tratamientos en los que no se requiere la identificación del afectado, cuando este, para el ejercicio de esos derechos, haya
              facilitado información adicional que permita su identificación, salvo que resultase de aplicación lo dispuesto en el artículo
              73 c) de esta ley orgánica.
            </p>
            <p>
              e) El incumplimiento de la obligación de notificación relativa a la rectificación o supresión de datos personales o la
              limitación del tratamiento exigida por el artículo 19 del Reglamento (UE) 2016/679.
            </p>
            <p>
              f) El incumplimiento de la obligación de informar al afectado, cuando así lo haya solicitado, de los destinatarios a los que
              se hayan comunicado los datos personales rectificados, suprimidos o respecto de los que se ha limitado el tratamiento.
            </p>
            <p>
              g) El incumplimiento de la obligación de suprimir los datos referidos a una persona fallecida cuando ello fuera exigible
              conforme al artículo 3 de esta ley orgánica.
            </p>
            <p>
              h) La falta de formalización por los corresponsables del tratamiento del acuerdo que determine las obligaciones, funciones y
              responsabilidades respectivas con respecto al tratamiento de datos personales y sus relaciones con los afectados al que se
              refiere el artículo 26 del Reglamento (UE) 2016/679 o la inexactitud en la determinación de las mismas.
            </p>
            <p>
              i) No poner a disposición de los afectados los aspectos esenciales del acuerdo formalizado entre los corresponsables del
              tratamiento, conforme exige el artículo 26.2 del Reglamento (UE) 2016/679.
            </p>
            <p>
              j) La falta del cumplimiento de la obligación del encargado del tratamiento de informar al responsable del tratamiento acerca
              de la posible infracción por una instrucción recibida de este de las disposiciones del Reglamento (UE) 2016/679 o de esta ley
              orgánica, conforme a lo exigido por el artículo 28.3 del citado reglamento.
            </p>
            <p>
              k) El incumplimiento por el encargado de las estipulaciones impuestas en el contrato o acto jurídico que regula el tratamiento
              o las instrucciones del responsable del tratamiento, salvo que esté legalmente obligado a ello conforme al Reglamento (UE)
              2016/679 y a la presente ley orgánica o en los supuestos en que fuese necesario para evitar la infracción de la legislación en
              materia de protección de datos y se hubiese advertido de ello al responsable o al encargado del tratamiento.
            </p>
            <p>
              l) Disponer de un Registro de actividades de tratamiento que no incorpore toda la información exigida por el artículo 30 del
              Reglamento (UE) 2016/679.
            </p>
            <p>
              m) La notificación incompleta, tardía o defectuosa a la autoridad de protección de datos de la información relacionada con una
              violación de seguridad de los datos personales de conformidad con lo previsto en el artículo 33 del Reglamento (UE) 2016/679.
            </p>
            <p>
              n) El incumplimiento de la obligación de documentar cualquier violación de seguridad, exigida por el artículo 33.5 del
              Reglamento (UE) 2016/679.
            </p>
            <p>
              ñ) El incumplimiento del deber de comunicación al afectado de una violación de la seguridad de los datos que entrañe un alto
              riesgo para los derechos y libertades de los afectados, conforme a lo exigido por el artículo 34 del Reglamento (UE) 2016/679,
              salvo que resulte de aplicación lo previsto en el artículo 73 s) de esta ley orgánica.
            </p>
            <p>
              o) Facilitar información inexacta a la Autoridad de protección de datos, en los supuestos en los que el responsable del
              tratamiento deba elevarle una consulta previa, conforme al artículo 36 del Reglamento (UE) 2016/679.
            </p>
            <p>
              p) No publicar los datos de contacto del delegado de protección de datos, o no comunicarlos a la autoridad de protección de
              datos, cuando su nombramiento sea exigible de acuerdo con el artículo 37 del Reglamento (UE) 2016/679 y el artículo 34 de esta
              ley orgánica.
            </p>
            <p>
              q) El incumplimiento por los organismos de certificación de la obligación de informar a la autoridad de protección de datos de
              la expedición, renovación o retirada de una certificación, conforme a lo exigido por los apartados 1 y 5 del artículo 43 del
              Reglamento (UE) 2016/679.
            </p>
            <p>
              r) El incumplimiento por parte de los organismos acreditados de supervisión de un código de conducta de la obligación de
              informar a las autoridades de protección de datos acerca de las medidas que resulten oportunas en caso de infracción del
              código, conforme exige el artículo 41.4 del Reglamento (UE) 2016/679.
            </p>
          </section>
          <section className="text-justify">
            <h2 className="text-primary h5">9. Responsabilidades</h2>
            <p>
              Las partes se comprometen a cumplir con sus obligaciones legales y contractuales generadas en virtud de las presentes
              Condiciones de contratación. Si una parte no cumple alguna de sus obligaciones u obstaculiza el cumplimiento por la otra parte
              de las suyas, se generará el derecho de esa otra parte a reclamar una indemnización por los daños y perjuicios causados, tanto
              por daño emergente como por lucro cesante.
            </p>
            <p>
              Las partes responderán de las infracciones en que hubiesen incurrido personalmente, quedando indemne la parte contraria frente
              a cualquier error, culpa o negligencia no imputable a ella, y a todo perjuicio que se derivase de dichas infracciones o
              errores imputables a la otra parte contratante.
            </p>
            <p>
              “EL TITULAR” en particular, no será responsable en caso de indisponibilidad temporal del Sitio web por razones técnicas o de
              mantenimiento previamente anunciado o de la imposibilidad de contratar los Servicios de software cuando ello sea por
              circunstancias ajenas a “EL TITULAR”, fuerza mayor o error en el proceso de contratación o datos facilitados por el usuario.
              Se realizarán los mayores esfuerzos para tratar de solventar esta situación con la petición del usuario.
            </p>
            <p>
              “EL TITULAR” empleará todos los esfuerzos técnicamente a su alcance para mantener disponibles los Servicios ofrecidos a través
              del Sitio web, lo que constituye una obligación que, no obstante, no será de aplicación a cualquier falta de disponibilidad o
              de rendimiento provocada por:
            </p>
            <ul>
              <li>
                <p>
                  Inactividad temporal del Sitio web debido a actualización y/o mantenimiento técnico o causas ajenas al control de “EL
                  TITULAR”;
                </p>
              </li>
              <li>
                <p>Fuerza mayor;</p>
              </li>
              <li>
                <p>Problemas de acceso a Internet;</p>
              </li>
              <li>
                <p>Problemas tecnológicos más allá de la gestión diligente y razonable del “EL TITULAR”;</p>
              </li>
              <li>
                <p>Acciones y omisiones de terceros.</p>
              </li>
            </ul>
            <p>
              En todos los casos referidos, ajenos al control y la diligencia debida por “EL TITULAR”, no habrá lugar a indemnización al
              usuario por lucro cesante, daños o perjuicios.
            </p>
            <p>
              El usuario exonera totalmente a “EL TITULAR” de cualquier responsabilidad que pudiera derivarse del contenido de la
              información suministrada o de las consecuencias para el usuario de la existencia de deficiencias en la misma.
            </p>
          </section>
          <section className="text-justify">
            <h2 className="text-primary h5">10. Usuarios registrados</h2>
            <h3 className="h6">10.1. Usuarios registrados</h3>
            <p>
              Un usuario debidamente validado, una vez acreditados los requisitos establecidos en el presente epígrafe, adquiere la
              condición de usuario registrado y le permite acceder al área privada, que estará debidamente protegida y con acceso
              restringido dentro del Sitio web, así como la adquisición y el uso de los Servicios de información de software.
            </p>
            <h3 className="h6">10.2. Requisitos para la adquisición de la condición de Usuario registrado</h3>
            <p>
              Pueden adquirir la condición de Usuario registrado las personas físicas y jurídicas. La solicitud de la adquisición de la
              condición de Usuario registrado implica la correcta cumplimentación del formulario de alta contenido en el Sitio web y la
              lectura y aceptación expresa, sin reserva alguna, de las presentes Condiciones de contratación contenido en el Sitio web, que
              se considera parte de las mismas. Únicamente podrá existir un Usuario registrado por NIF, NIE, Pasaporte o Tarjeta de
              Identificación Nacional (Identity Card).
            </p>
            <p>
              La aceptación se manifestará al confirmar la solicitud de alta. Dicha solicitud se realizará siempre por una persona física
              identificada, mayor de edad y con plena capacidad para contratar. Cuando la solicitud de alta se realice en nombre de una
              persona jurídica, se comprobará que quien cursa el alta tiene capacidad para contratar en su nombre como apoderado o
              administrador, esta verificación se realizará de forma automática con base en los ficheros del “EL TITULAR”. Caso de no ser
              posible, se requerirá la documentación justificativa de la representación ostentada. La persona física solicitante será
              responsable de la veracidad de la información facilitada durante el proceso de alta y responderá personalmente de cualquier
              consecuencia derivada de la falta de autenticidad de la misma.
            </p>
            <p>
              El Usuario registrado declara que toda la información incluida en el formulario deberá ser veraz, actual y ajustada a la
              realidad. Asimismo, el Usuario registrado se compromete a actualizar toda la información sobre su información fiscal en caso
              de producirse una alteración a través del Área privada, no siendo el “EL TITULAR” responsable de los perjuicios que de ello
              pudieran derivarse.
            </p>
            <p>
              La falta de la citada comunicación exime tanto al “EL TITULAR” de cualquier responsabilidad, siendo el usuario el único
              responsable frente a terceros por cuantas acciones legales o judiciales se inicien por su incumplimiento.
            </p>
            <h3 className="h6">10.3. Aceptación de las Condiciones de contratación y cumplimiento de obligaciones</h3>
            <p>
              Todo Usuario deberá aceptar y cumplir las presentes Condiciones de contratación y muy especialmente, se compromete
              expresamente al cumplimiento de las siguientes obligaciones:
            </p>
            <ul>
              <li>
                <p>
                  Veracidad: El Usuario deberá facilitar información veraz y mantener actualizada, en todo momento, la información que
                  facilite. El usuario será el único responsable de las manifestaciones falsas o inexactas que realice y de los perjuicios
                  que cause a “EL TITULAR” o a terceros por la información que facilite.
                </p>
              </li>
              <li>
                <p>
                  Confidencialidad: El Usuario deberá observar el deber de secreto respecto de los datos de carácter personal de los que
                  tenga conocimiento a través del software, manteniendo absoluta confidencialidad y reserva sobre los mismos. El
                  incumplimiento de esta obligación dará lugar a las consecuentes responsabilidades.
                </p>
              </li>
              <li>
                <p>
                  Actualización: El Usuario declara que toda la información incluida en el formulario es actual y veraz. Asimismo, el
                  Usuario registrado se compromete a actualizar (a través del Administrador) toda la información en caso de producirse
                  cualquier alteración en la misma. Se deberán tener actualizados los nombres, apellidos y documentos identificativos en
                  todo momento.
                </p>
              </li>
              <li>
                <p>
                  Exención de responsabilidad: La falta de la citada comunicación exime a “EL TITULAR” de cualquier responsabilidad, siendo
                  el usuario el único responsable frente a terceros por cuantas acciones legales o judiciales se inicien por su
                  incumplimiento.
                </p>
              </li>
            </ul>
            <h3 className="h6">10.6. Acceso a los Servicios y gestión de identificadores</h3>
            <p>
              El acceso al Área privada requiere del uso de un mecanismo de identificación por parte del Usuario, que podrá consistir en el
              uso de un certificado digital o de las claves (nombre de usuario y contraseña) que le sean facilitadas para este fin,
              cumpliendo con los requisitos de robustez y seguridad establecidas por el “EL TITULAR”.
            </p>
            <p>
              El Usuario será responsable de mantener la confidencialidad de su Identificador. En consecuencia, el Usuario acepta
              expresamente que “EL TITULAR” presuma que los usos de los Servicios realizados utilizando su Identificador son efectuados por
              el mismo.
            </p>
            <p>
              La contraseña podrá ser modificada libremente por el Usuario a través de los procedimientos establecidos al efecto. La
              contraseña sustituida quedará anulada como medio de identificación, en el mismo momento en que se genere la nueva. Si tiene
              conocimiento de que la seguridad de su contraseña se ha visto comprometida, ya sea de modo fortuito o fraudulento, por
              personas no autorizadas, el Usuario deberá proceder a modificarla de modo inmediato.
            </p>
            <p>
              “EL TITULAR” podrá bloquear el acceso y utilización del Sitio web cuando lo estime necesario por motivos de seguridad. Los
              Servicios quedarán bloqueados automáticamente para el Usuario en el supuesto de que se produzcan reiterados errores sucesivos
              en la consignación de las claves del Usuario para su acceso o utilización.
            </p>
            <p>
              “EL TITULAR” adoptará las medidas organizativas y técnicas necesarias en sus equipos informáticos para lograr una adecuada
              utilización del Servicio por el Usuario y evitar accesos no autorizados cuyo objeto sea proceder a revelaciones no autorizadas
              del contenido del Área privada.
            </p>
            <p>
              La posibilidad de la solicitud del SBDC de un Usuario desde el Área privada quedará condicionada a la correcta configuración
              de una cuenta virtual para el pago de los Servicios, vinculado al Usuario.
            </p>
          </section>
          <section className="text-justify">
            <h2 className="text-primary h5">11. Cláusula de salvaguarda</h2>
            <p>
              En caso de que una de las cláusulas o extremos de las presentes Condiciones de contratación sea declarada nula por sentencia
              judicial o resolución arbitral firme, el resto de estipulaciones no se verán afectadas. En dicho supuesto, se sustituirá la
              cláusula o cláusulas afectadas por otra u otras que preserven los efectos perseguidos por las Condiciones de contratación del
              Sitio web.
            </p>
          </section>
          <section>
            <h2 className="text-primary h5">12. Jurisdicción y legislación aplicables</h2>
            <p>
              La normativa vigente determinará las leyes que deban regir y la jurisdicción que deba conocer de las relaciones entre “EL
              TITULAR” y los usuarios del Sitio web (el domicilio del usuario cuando este tenga la consideración de consumidor). No
              obstante, siempre que tal normativa prevea la posibilidad para las partes de someterse a un fuero determinado, para toda
              cuestión litigiosa derivada o relacionada con el Sitio web, será de aplicación la legislación española vigente en el momento
              de los hechos. Asimismo, “EL TITULAR” y los usuarios, con renuncia expresa a cualquier otro fuero que pudiera corresponderles,
              se someten a los Juzgados y Tribunales de la ciudad de Madrid (España).
            </p>
          </section>
          <section className="text-justify">
            <h2 className="text-primary h5 text-left">ANEXO - INFORMACIÓN COMPLETA PROTECCIÓN DE DATOS PERSONALES</h2>
            <p>Política de protección de datos aplicable al ”EL TITULAR” como Responsable del Tratamiento en el uso de sus servicios:</p>
            <p>
              A los efectos establecidos en el REGLAMENTO (UE) 2016/679 DEL PARLAMENTO EUROPEO Y DEL CONSEJO de 27 de abril de 2016 relativo
              a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de
              estos datos y por el que se deroga la Directiva 95/46/CE (Reglamento General de Protección de Datos) , se informa al Usuario
              que los datos de carácter personal facilitados serán objeto de tratamiento, actuando el “EL TITULAR” tanto como responsable
              del tratamiento (y cuyos datos identificativos constan en el encabezado del presente documento) como encargado del
              tratamiento.
            </p>
            <p>
              ¿Cuál es la finalidad de tratamiento, los plazos de conservación y los destinatarios de la información de carácter personal?
              Esta información varía dependiendo del servicio utilizado, si bien en el marco del presente contrato la información aplicable
              al servicio contratado es, como mínimo, la siguiente:
            </p>
            <ul>
              <li>
                <p>
                  En el caso de uso de la página web de “EL TITULAR” para la contratación del servicio, la finalidad es la gestión y control
                  de los Usuarios de alta en la Página Web, la prestación y contratación de los Servicios realizada por los Usuarios a
                  través de la misma, la tramitación de los pagos derivados de la prestación de dichos servicios, así como el análisis del
                  uso de la Página Web.
                </p>
              </li>
              <li>
                <p>
                  La legitimación o base jurídica de estos tratamientos es la suscripción/ejecución por parte del usuario del presente
                  contrato.
                </p>
              </li>
              <li>
                <p>
                  Los criterios de conservación de los datos personales para estas finalidades serán los siguientes. Los datos personales
                  vinculados a la relación contractual serán conservados durante la vigencia de dicha relación contractual con el Usuario y
                  como máximo, durante el plazo de prescripción de las acciones legales correspondientes, estableciéndose en 7 años, salvo
                  que el interesado autorice su tratamiento por un plazo superior.
                </p>
              </li>
            </ul>
            <p>
              No se comunicarán los datos a más destinatarios excepto en los casos legalmente establecidos en el derecho nacional o europeo.
              En el formulario de captura de datos se especificará qué datos se pueden incluir opcionalmente y cuáles son los datos mínimos
              obligatorios, que son los estrictamente necesarios para poder cumplir con las finalidades antes mencionadas, en especial con
              la identificación del usuario y apreciación de su interés legítimo para el acceso a la información, así como para la
              facturación de los servicios. La negativa a facilitar los datos marcados como obligatorios impedirá la contratación de los
              servicios.
            </p>
            <p>
              En los casos en los que el Usuario proporcione su número de tarjeta con la finalidad de poder proceder a prestar los servicios
              solicitados, se comunica que este dato se transmite directamente al proveedor de pasarela de pagos Ingenico eCommerce
              Solutions que es quien procede al almacenamiento seguro de dichos datos en sus servidores. Para el ejercicio de sus derechos
              puede dirigirse a este proveedor en los términos que se establezcan en sus políticas de privacidad. Se informa al usuario que
              “EL TITULAR” no tiene acceso en ningún momento a los datos de tarjeta proporcionados, y que la transmisión de estos datos es
              necesaria para la prestación del servicio de pago por tarjeta.
            </p>
            <p>
              Los siguientes casos aplican exclusivamente en caso de hacer uso de los servicios referenciados como consta en el presente
              contrato:
            </p>
            <ul>
              <li>
                <p>
                  En caso de hacer uso de los Servicios de Atención al Usuario de “EL TITULAR” en los términos indicados en el presente
                  documento, se informa al usuario que la base que legitima el tratamiento es el propio consentimiento del usuario con la
                  finalidad de atender las consultas que formule de forma proactiva, el cual podrá revocar en cualquier momento a través de
                  los canales habilitados en la web, y el interés legítimo de “EL TITULAR” en los casos de contacto con el usuario para la
                  resolución de cuestiones, comunicaciones o incidencias relativas al servicio. Los datos que facilite a estos efectos se
                  conservarán durante el tiempo necesario para atender su consulta y aún después, en caso de existiera alguna
                  responsabilidad derivada del tratamiento realizado. Se informa al usuario que, en caso de utilizar el servicio de atención
                  telefónica, las llamadas pueden ser grabadas por motivos de calidad del servicio, en base al interés legítimo de “EL
                  TITULAR”. En este caso, los datos se conservarán un máximo de 6 meses. El uso de estos servicios no condiciona la
                  ejecución del presente contrato, siendo servicios de soporte para prestar tal servicio.
                </p>
              </li>
            </ul>
            <p>
              El resto de información que en cualquier caso afecta al tratamiento de datos realizado en el marco de la ejecución del
              presente contrato es el siguiente:
            </p>
            <ul>
              <li>
                <p>
                  “EL TITULAR” ha adoptado las medidas técnicas y organizativas necesarias para garantizar la confidencialidad, seguridad e
                  integridad de los datos personales facilitados, así como para evitar su alteración, pérdida, tratamiento o acceso no
                  autorizados, de acuerdo con el nivel de protección acorde a la legislación de protección de datos.
                </p>
              </li>
              <li>
                <p>
                  Con el fin de mantener sus datos personales actualizados, el Usuario deberá informar de cualquier cambio que se produzca
                  respecto a ellos, pues en caso contrario “EL TITULAR” no podrá responder de su veracidad, no siendo responsable de los
                  perjuicios que puedan derivarse de ello en la prestación de los servicios. En todos los casos, “EL TITULAR” se reserva el
                  derecho a conservar los datos de los Usuarios en los casos en que ello fuera necesario para dar cumplimiento a las
                  obligaciones y responsabilidades impuestas por las leyes o normas de obligado cumplimiento, o cuando fuera requerida para
                  ello por mandato de autoridad competente con arreglo a Derecho.
                </p>
              </li>
              <li>
                <p>
                  El Usuario puede enviar un escrito a la dirección del “EL TITULAR” o a través de correo electrónico dpd@gamalex.es, con la
                  Referencia "Protección de Datos", adjuntando fotocopia de su documento de identidad, en cualquier momento y de manera
                  gratuita, para ejercer los siguientes derechos:
                </p>
              </li>
              <li>
                <p>Derecho a solicitar el acceso a los datos personales relativos al interesado.</p>
                <ul>
                  <li>
                    <p>Derecho a solicitar su rectificación o supresión.</p>
                  </li>
                  <li>
                    <p>Derecho a solicitar la limitación de su tratamiento.</p>
                  </li>
                  <li>
                    <p>Derecho a oponerse al tratamiento.</p>
                  </li>
                  <li>
                    <p>Derecho a la portabilidad de los datos.</p>
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  En los casos en los que la legitimación del tratamiento esté basada en el consentimiento, tiene derecho a retirarlo en
                  cualquier momento, sin que ello afecte a la licitud del tratamiento basado en el consentimiento previo a su retirada.
                </p>
              </li>
              <li>
                <p>
                  Le informamos que puede haber limitaciones en el ejercicio de los derechos anteriormente descritos en función del
                  tratamiento específico al que se refiera.
                </p>
              </li>
              <li>
                <p>
                  También puede hacer uso de los formularios puestos a disposición por la Agencia Española de Protección de Datos (AEPD). A
                  la vez el usuario puede reclamar ante dicha Agencia (www.aepd.es) cuando considere que se han vulnerado los derechos que
                  le son reconocidos por la normativa aplicable de protección de datos.
                </p>
              </li>
              <li>
                <p>
                  Podrá ponerse en contacto con el Delegado de Protección de Datos del “EL TITULAR” a través del Correo electrónico:
                  dpd@gamalex.es.
                </p>
              </li>
            </ul>
          </section>
        </div>

        <div className="card-footer px-6">
          <div className="d-flex justify-content-between">
            <div className="form-check text-left my-auto">
              <input
                className={`form-check-input ${!isAccepted && "micro"}`}
                type="checkbox"
                checked={isAccepted}
                id="flexCheckDefault"
                onChange={handleCheckboxChange}
                disabled={checkoutIsLoading || isLoadingLicense}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                Acepto los términos y condiciones
              </label>
            </div>
            <button className="btn btn-primary" onClick={handleAccept} disabled={checkoutIsLoading || isLoadingLicense || !isAccepted}>
              {checkoutIsLoading || isLoadingLicense ? (
                <div className="px-4">
                  <div className="spinner-border spinner-border-sm align-middle" role="status" />
                </div>
              ) : (
                "Aceptar"
              )}
            </button>
          </div>
        </div>
      </div>

      <div className="mt-6">
        <a href="https://www.capterra.com/p/10001468/MailerFind/reviews?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge">
          <img
            className="img-fluid mr-3"
            style={{ height: "2.85rem" }}
            alt="Capterra Badge"
            src="https://assets.capterra.com/badge/402dbe2eaf3c39a0fc40c578ced384c5.svg?v=2225476&p=277155"
          />
        </a>
        <a href="https://www.softwareadvice.com/marketing/mailerfind-profile/reviews/">
          <img
            alt="Software Advice Badge"
            className="img-fluid"
            style={{ height: "2.85rem" }}
            src="https://badges.softwareadvice.com/reviews/82c84a6e-c82a-4ead-babf-3217247ccc5c"
          />
        </a>
      </div>
    </div>
  );
}

export default Disclaimer;

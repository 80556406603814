import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { ArrowUpCircle, Check, TicketFill } from "react-bootstrap-icons";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { getMyCoupon, redeemOffer } from "../api/mailerfind/stripe";
import { auth } from "../lib/firebase/firebase";
import Skeleton from "react-loading-skeleton";
import Moment from "react-moment";

function PremiumModal({ show, setShow, title }: any) {
  const { t } = useTranslation("Component.PremiumModal");
  const promotionIsAvailable = true;
  const [coupon, setCoupon] = useState(null) as any;
  const [loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);

  const handleApplyCoupon = async () => {
    try {
      setLoading(true);
      const jwt = await auth.currentUser?.getIdToken();
      const response = await redeemOffer(jwt);
      setCoupon(response.coupon);
    } catch (err: any) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const getCoupon = async () => {
    try {
      setLoading(true);
      const jwt = await auth.currentUser?.getIdToken();
      const response = await getMyCoupon(jwt);
      if (!response.coupon) return;
      if (response.coupon.redeem_by < Math.floor(Date.now() / 1000)) {
        response.coupon.expired = true;
      } else response.coupon.expired = false;
      setCoupon(response.coupon);
    } catch (err: any) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleRedirect = () => {
    if (coupon) {
      window.location.href = "https://mailerfind.com/es/pricing/oferta";
    } else {
      window.location.href = "https://mailerfind.com/es/pricing/upgrade";
    }
  };

  useEffect(() => {
    if (show === false) return;
    getCoupon();
  }, [show]);

  return (
    <>
      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Body className="p-0">
          <div className="row me-0">
            <div className="col-lg-6 col-0 d-none d-lg-block p-0">
              <img
                src="/assets/img/upgrade.jpg"
                className="img-fluid"
                alt="Premium Upgrade"
                style={{
                  borderTopLeftRadius: "0.75rem",
                  borderBottomLeftRadius: "0.75rem",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
            <div className="col-lg-6 col-12 px-6 py-6 my-auto">
              <h2>{title || t("title")}</h2>
              <p>{t("description")}</p>
              <p>
                <Check className="text-primary align-middle mr-2" size={16} />
                {t("features.feature1")}
              </p>
              <p>
                <Check className="text-primary align-middle mr-2" size={16} />
                {t("features.feature2")}
              </p>
              <p>
                <Check className="text-primary align-middle mr-2" size={16} />
                {t("features.feature3")}
              </p>
              <p>
                <Check className="text-primary align-middle mr-2" size={16} />
                {t("features.feature4")}
              </p>
              <p>
                <Check className="text-primary align-middle mr-2" size={16} />
                {t("features.feature5")}
              </p>

              <CouponBox
                coupon={coupon}
                handleAppyCoupon={handleApplyCoupon}
                loading={loading}
                promotionIsAvailable={promotionIsAvailable}
              />

              <button className="btn btn-primary w-100 mt-2" disabled={loading} onClick={handleRedirect}>
                <ArrowUpCircle className="mr-1 align-middle" />
                <span className="align-middle">{coupon && !coupon.expired ? t("viewDiscountedPlans") : t("viewPlans")}</span>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

function CouponBox({ coupon, handleAppyCoupon, loading, promotionIsAvailable }: any) {
  const { t } = useTranslation("Component.PremiumModal");
  return (
    <>
      {coupon ? (
        <>
          {!coupon.expired && (
            <div className="alert alert-success mt-2" role="alert">
              <p className="m-0">
                {t("couponApplied", { discount: coupon.percent_off })}
                <Moment locale="es" unix format="dddd D [de] MMMM [de] YYYY">
                  {coupon.redeem_by}
                </Moment>
                .
              </p>
            </div>
          )}
        </>
      ) : (
        <>
          {loading && promotionIsAvailable ? (
            <>
              <Skeleton height={55} className="mb-2" />
            </>
          ) : (
            <>
              {promotionIsAvailable && (
                <div className="form-check text-primary mb-2 border rounded p-3">
                  <input
                    type="checkbox"
                    id="formCheck11"
                    className="form-check-input"
                    style={{ marginTop: "4px", marginLeft: "3px" }}
                    checked={coupon ? true : false}
                    onChange={handleAppyCoupon}
                  />
                  <label className="form-check-label" htmlFor="formCheck11">
                    <span className="badge bg-primary text-white mt-0 mr-2 pt-2 align-middle" style={{ paddingBottom: "5px" }}>
                      <TicketFill className="align-middle mr-2 p-0" style={{ marginBottom: 1 }} />
                      {t("coupon")}
                    </span>
                    <span className="pt-2">{t("applyDiscount")}</span>
                  </label>
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default PremiumModal;

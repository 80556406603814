import {
  doc,
  getDoc,
  getDocs,
  collection,
  query,
  addDoc,
  deleteDoc,
  updateDoc,
  limit,
  orderBy,
  Firestore,
  writeBatch,
  where,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../../lib/firebase/firebase";

export const getAnalysisById = async (userId: string, analysisId: string): Promise<any> => {
  const analysisDocRef = doc(db, "users", userId, "analysis", analysisId);
  const docSnap = await getDoc(analysisDocRef);

  if (docSnap.exists()) {
    const analysis = docSnap.data();
    return { id: docSnap.id, ...analysis };
  } else {
    console.log("No such analysis!");
    return null;
  }
};

export const listenAnalysis = (
  userId: string,
  analysisId: string,
  onUpdate: (analysis: any | null) => void,
  onError: (error: Error) => void
) => {
  // Referencia al documento del análisis dentro de la colección de usuarios
  const analysisDocRef = doc(db, "users", userId, "analysis", analysisId);

  // Suscripción en tiempo real al documento del análisis
  const unsubscribe = onSnapshot(
    analysisDocRef,
    (docSnap) => {
      // Si el documento no existe, invocamos el callback con null
      if (!docSnap.exists()) {
        onUpdate(null);
        return;
      }
      // Obtenemos los datos y añadimos el id del documento
      const analysis = docSnap.data();
      onUpdate({ id: docSnap.id, ...analysis });
    },
    // En caso de error, se ejecuta el errorCallback
    (err) => {
      onError(err);
    }
  );

  // Devolvemos la función de desuscripción para que se pueda cancelar la escucha en cualquier momento
  return unsubscribe;
};

export const getAllAnalysis = async (userId: string, status?: string[] | null, project?: string): Promise<any[]> => {
  const analysisCollectionRef = collection(db, "users", userId, "analysis");
  let q;

  if (status && status.length > 0 && project) {
    // Filtra por array de estados y proyecto si ambos parámetros son proporcionados y limita a 250 resultados
    q = query(analysisCollectionRef, where("status", "in", status), where("project", "==", project), limit(250));
  } else if (status && status.length > 0) {
    // Filtra solo por array de estados si solo el estado es proporcionado y limita a 250 resultados
    q = query(analysisCollectionRef, where("status", "in", status), where("project", "==", null), limit(250));
  } else if (project) {
    // Filtra solo por proyecto si solo el proyecto es proporcionado y limita a 250 resultados
    q = query(analysisCollectionRef, where("project", "==", project), limit(250));
  } else {
    // Si no se proporciona ningún filtro, obtiene todos los análisis limitando a 250 resultados
    q = query(analysisCollectionRef, where("project", "==", null), limit(250));
  }

  const querySnapshot = await getDocs(q);
  const analysisList = [] as any[];
  querySnapshot.forEach((doc) => {
    analysisList.push({ id: doc.id, ...doc.data() });
  });

  return analysisList;
};

export const createAnalysis = async (userId: string, analysisName: any, options: any | null = null): Promise<string> => {
  const newAnalysis = {
    version: options?.version || 2,
    name: analysisName,
    status: "NOT_STARTED",
    selectedItem: options?.selectedItem || null,
    mode: options?.mode || null,
    businessAccountsCount: 0,
    emailsFoundCount: 0,
    prospectsCount: 0,
    publicAccountsCount: 0,
    privateAccountsCount: 0,
    phoneNumbersFoundCount: 0,
    websitesFoundCount: 0,
    creditsUsed: 0,
    page: 0,
    cursor: null,
    createdAt: new Date(),
    isCloud: true,
    project: options?.project || null,
  };
  const analysisCollectionRef = collection(db, "users", userId, "analysis");
  const docRef = await addDoc(analysisCollectionRef, newAnalysis);

  return docRef.id;
};

export const updateAnalysis = async (userId: string, analysisId: string, data: any): Promise<void> => {
  const analysisDocRef = doc(db, "users", userId, "analysis", analysisId);
  const dataCopy = { ...data };
  delete dataCopy.usageMetrics;
  delete dataCopy.emailsFoundCount;
  delete dataCopy.phoneNumbersFoundCount;
  delete dataCopy.businessAccountsCount;
  delete dataCopy.websitesFoundCount;
  delete dataCopy.creditsUsed;
  delete dataCopy.publicAccountsCount;
  await updateDoc(analysisDocRef, dataCopy);
};

export const deleteAnalysis = async (userId: string, analysisId: string): Promise<void> => {
  // Borrar la subcolección 'prospects' asociada al análisis
  await deleteCollection(db, `users/${userId}/analysis/${analysisId}/prospects`, 50);

  const analysisDocRef = doc(db, "users", userId, "analysis", analysisId);
  // const analysis = await getAnalysisById(userId, analysisId);

  // Añadir un nuevo evento en el array de events del usuario indicando que ha eliminado este analisis
  /*const userDocRef = doc(db, "users", userId);

  const newEvent = {
    type: "DELETE_ANALYSIS",
    analysisId: analysisId,
    analysisName: analysis.name,
    date: new Date(),
  };
  await updateDoc(userDocRef, { events: arrayUnion(newEvent) });*/

  // Finalmente, eliminar el documento principal del análisis
  await deleteDoc(analysisDocRef);
};

async function deleteCollection(db: Firestore, collectionPath: string, batchSize: number): Promise<void> {
  const collRef = collection(db, collectionPath);
  const q = query(collRef, orderBy("__name__"), limit(batchSize));

  return new Promise((resolve, reject) => {
    deleteQueryBatch(db, q, batchSize, resolve).catch(reject);
  });
}

async function deleteQueryBatch(db: Firestore, q: ReturnType<typeof query>, batchSize: number, resolve: () => void): Promise<void> {
  const snapshot = await getDocs(q);

  if (snapshot.size === 0) {
    resolve();
    return;
  }

  const batch = writeBatch(db);
  snapshot.docs.forEach((doc: any) => {
    batch.delete(doc.ref);
  });
  await batch.commit();

  setTimeout(() => {
    deleteQueryBatch(db, q, batchSize, resolve);
  }, 0);
}

// usePixelTracking.ts
import { useEffect } from "react";
import ReactPixel from "react-facebook-pixel";

// Extensión de la interfaz global para TikTok
declare global {
  interface Window {
    ttq?: {
      track: (eventName: string, eventData?: Record<string, any>, options?: Record<string, any>) => void;
      identify: (data: Record<string, string>) => void;
    };
  }
}

// Interfaces para eventos
interface MetaEvent {
  eventName: string;
  eventData?: Record<string, any>;
}

interface TikTokEvent {
  eventName: string;
  eventData?: Record<string, any>;
  options?: Record<string, any>;
}

interface PixelEventMapping {
  meta?: MetaEvent;
  tiktok?: TikTokEvent;
}

const usePixelTracking = () => {
  useEffect(() => {
    // Inicialización de Facebook Pixel (reemplaza con tu ID real)
    ReactPixel.init("351950697186720");
  }, []);

  const sendEvent = (mapping: PixelEventMapping) => {
    // Envío a Meta
    if (mapping.meta) {
      ReactPixel.trackCustom(mapping.meta.eventName, mapping.meta.eventData);
    }
    // Envío a TikTok
    if (mapping.tiktok) {
      if (window.ttq && typeof window.ttq.track === "function") {
        window.ttq.track(mapping.tiktok.eventName, mapping.tiktok.eventData, mapping.tiktok.options);
      } else {
        console.warn("TikTok Pixel no está inicializado.");
      }
    }
  };

  const identifyUser = (data: Record<string, string>) => {
    if (window.ttq && typeof window.ttq.identify === "function") {
      window.ttq.identify(data);
    } else {
      console.warn("TikTok Pixel no está inicializado o no soporta identify.");
    }
  };

  // Función helper para el evento de registro completo
  const trackCompleteRegistration = (
    userId: string,
    additionalMetaData?: Record<string, any>,
    tiktokData?: {
      contents: Array<Record<string, any>>;
      value: number;
      currency: string;
      options?: Record<string, any>;
    }
  ) => {
    // Se pueden definir valores por defecto o realizar transformaciones aquí
    sendEvent({
      meta: {
        eventName: "CompleteRegistration",
        eventData: { userId, ...additionalMetaData },
      },
      tiktok: tiktokData
        ? {
            eventName: "CompleteRegistration",
            eventData: {
              ...tiktokData,
            },
            options: tiktokData.options,
          }
        : undefined,
    });
  };

  return { sendEvent, identifyUser, trackCompleteRegistration };
};

export default usePixelTracking;

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { auth } from "../../lib/firebase/firebase";
import Chart from "./Chart";
import Loading from "../../components/Loading";
import { analysisIsCloud } from "../../utils/helpers";
import Table from "./Table";
import { Cursor, EnvelopeOpen, PatchCheckFill } from "react-bootstrap-icons";
import { listenAnalysis } from "../../lib/firebase/analysis";
import { listenEmail } from "../../lib/firebase/emails";
import toast from "react-hot-toast";
import TableClicked from "./TableClicked";
import { Email } from "../../types/EmailTypes";
import { getClickedProspects, getReadProspects } from "../../lib/firebase/prospects";

function Analytics() {
  const { t } = useTranslation("ViewEmail.index");
  const { id } = useParams() as any;
  const [loading, setLoading] = useState(true);
  const [user] = useState(auth.currentUser) as any;

  // Email related data
  const [analysis, setAnalysis] = useState<any>(null);
  const [email, setEmail] = useState<Email | null>(null);
  const [readProspects, setReadProspects] = useState<any[]>([]);
  const [clickedProspects, setClickedProspects] = useState<any[]>([]) as any;
  const [totalUsers, setTotalUsers] = useState(0);

  const calculateTotalUsers = (email: any, followUpEmail: any, analysis: any) => {
    if (email?.followUp?.isAFollowUpEmail === true) {
      switch (email?.followUp?.followUpMode) {
        case "open":
          return analysis.emailsFoundCount - followUpEmail.opens;
        case "click":
          return analysis.emailsFoundCount - (followUpEmail.uniqueClicks || followUpEmail.clicks);
        default:
          return toast.error(t("errors.followUpModeNotRecognized"));
      }
    } else {
      return analysis.emailsFoundCount;
    }
  };

  useEffect(() => {
    if (!id) return setLoading(false);
    const unsubscribe = listenEmail(
      user.uid,
      id,
      (email) => setEmail(email),
      (error) => toast.error(error.message)
    );

    return () => unsubscribe();
  }, [id]);

  useEffect(() => {
    if (!id) return setLoading(false);
    if (!email) return;
    const unsubscribe = listenAnalysis(
      user.uid,
      email.analysis,
      (analysis) => {
        setAnalysis(analysis);
        setLoading(false);
        const totalUsers = calculateTotalUsers(email, null, analysis);
        setTotalUsers(totalUsers);
      },
      (error) => toast.error(error.message)
    );

    return () => unsubscribe();
  }, [id, email]);

  const fetchReadAndClickedProspects = async (email: any) => {
    const [readProspectsResponse, clickedProspectsResponse] = await Promise.all([
      getReadProspects(email.analysis, email.id, user.uid, 1000, null),
      getClickedProspects(email.analysis, email.id, user.uid, 1000, null),
    ]);

    setReadProspects(readProspectsResponse.prospects);
    setClickedProspects(clickedProspectsResponse.prospects);
  };

  useEffect(() => {
    if (!email) return;
    fetchReadAndClickedProspects(email);
  }, [email]);

  if (loading) return <Loading />;
  if (!email) return null;
  if (!analysis) return null;

  return (
    <>
      <div className="row">
        <div className="col-sm-6 col-lg-3 mb-3 mb-lg-5">
          <a className="card card-hover-shadow h-100" href="#">
            <div className="card-body">
              <h6 className="card-subtitle">{t("totalUsers")}</h6>
              <div className="row align-items-center gx-2 mb-1">
                <div className="col-6">
                  <h2 className="card-title text-inherit">{totalUsers || 0}</h2>
                </div>
              </div>
              <span className="badge bg-soft-primary text-primary">{analysis?.name?.toUpperCase()}</span>
            </div>
          </a>
        </div>
        <div className="col-sm-6 col-lg-3 mb-3 mb-lg-5">
          <a className="card card-hover-shadow h-100" href="#">
            <div className="card-body">
              <h6 className="card-subtitle">{t("sent")}</h6>
              <div className="row align-items-center gx-2 mb-1">
                <div className="col-6">
                  <h2 className="card-title text-inherit">{email?.sent || 0}</h2>
                </div>
              </div>
              <span className="badge bg-soft-primary text-primary">{(((email?.sent || 0) / (totalUsers || 1)) * 100).toFixed(2)}%</span>
            </div>
          </a>
        </div>
        <div className="col-sm-6 col-lg-3 mb-3 mb-lg-5">
          <a className="card card-hover-shadow h-100" href="#">
            <div className="card-body">
              <h6 className="card-subtitle">{t("openRate")}</h6>
              <div className="row align-items-center gx-2 mb-1">
                <div className="col-6">
                  <h2 className="card-title text-inherit">{(((email?.opens || 0) / (email?.sent || 1)) * 100).toFixed(0)}%</h2>
                </div>
              </div>
              {email?.sent === 0 ? (
                <span className="badge bg-soft-secondary text-secondary">{t("startSendingToViewStats")}</span>
              ) : (
                <>
                  {Number((((email?.opens || 0) / (email?.sent || 1)) * 100).toFixed(0)) < 10 && (
                    <span className="badge bg-soft-danger text-danger">{t("probablySpam")}</span>
                  )}
                  {Number((((email?.opens || 0) / (email?.sent || 1)) * 100).toFixed(0)) >= 10 &&
                    Number((((email?.opens || 0) / (email?.sent || 1)) * 100).toFixed(0)) < 30 && (
                      <span className="badge bg-soft-warning text-warning">{t("canBeImproved")}</span>
                    )}
                  {Number((((email?.opens || 0) / (email?.sent || 1)) * 100).toFixed(0)) >= 30 && (
                    <span className="badge bg-soft-success text-success">{t("good")}</span>
                  )}
                </>
              )}
            </div>
          </a>
        </div>
        <div className="col-sm-6 col-lg-3 mb-3 mb-lg-5">
          <a className="card card-hover-shadow h-100" href="#">
            <div className="card-body">
              <h6 className="card-subtitle">{t("uniqueClicks")}</h6>
              <div className="row align-items-center gx-2 mb-1">
                <div className="col-6">
                  <h2 className="card-title text-inherit">{email?.uniqueClicks || email?.clicks || 0}</h2>
                </div>
              </div>
              <span className="badge bg-soft-primary text-primary">
                {(((email?.uniqueClicks || email?.clicks || 0) / (email?.opens || 1)) * 100).toFixed(2)}% {t("clickRate")}
              </span>
            </div>
          </a>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 mb-3">
          <div className="card card-hover-shadow h-100">
            <div className="card-header">
              <h5 className="card-title">{t("emailStats")}</h5>
            </div>
            <div className="card-body">
              <Chart
                totals={totalUsers || 0}
                sent={email?.sent || 0}
                read={email?.opens || 0}
                clicks={email?.uniqueClicks || email?.clicks || 0}
                unsubscribed={email?.unsubscribed || 0}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6 mb-3">
          {analysisIsCloud(email.analysis) ? (
            <div className="card card-hover-shadow h-100" style={{ maxHeight: "380px", overflowY: "auto" }}>
              <div className="card-header">
                <h5 className="card-title">
                  <EnvelopeOpen /> {t("openedBy")}
                </h5>
              </div>
              {readProspects.length === 0 ? (
                <div className="text-center py-5">
                  <img src="/assets/svg/illustrations/graphs.svg" className="img-fluid" style={{ maxWidth: "220px" }} />
                  <h5 className="mt-3">{t("noProspectsYet")}</h5>
                  <p>{t("startSendingToViewWhoOpened")}</p>
                </div>
              ) : (
                <Table users={readProspects} />
              )}
            </div>
          ) : (
            <div className="card card-hover-shadow h-100">
              <div className="card-header">
                <h5 className="card-title">
                  <EnvelopeOpen /> {t("openedBy")}
                </h5>
              </div>
              <div className="card-body p-0 m-0 pb-2 position-relative">
                <img src="/assets/img/pro.png" className="round img-fluid" alt="pro" />
                <div className="h1 text-white text-center position-absolute" style={{ top: "50%", width: "100%" }}>
                  <span className="badge bg-primary rounded-pill text-uppercase">
                    <PatchCheckFill /> <span className="mt-3">{t("unlimited")}</span>
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 mb-3">
          {analysisIsCloud(email.analysis) ? (
            <div className="card card-hover-shadow h-100" style={{ maxHeight: "380px", overflowY: "auto" }}>
              <div className="card-header">
                <h5 className="card-title">
                  <Cursor /> {t("clickedBy")}
                </h5>
              </div>
              {clickedProspects.length === 0 ? (
                <div className="text-center py-5">
                  <img src="/assets/svg/illustrations/graphs.svg" className="img-fluid" style={{ maxWidth: "220px" }} />
                  <h5 className="mt-3">{t("noProspectsYet")}</h5>
                  <p>{t("startSendingToViewWhoClicked")}</p>
                </div>
              ) : (
                <TableClicked users={clickedProspects} />
              )}
            </div>
          ) : (
            <div className="card card-hover-shadow h-100">
              <div className="card-header">
                <h5 className="card-title">
                  <Cursor /> {t("clickedBy")}
                </h5>
              </div>
              <div className="card-body p-0 m-0 pb-2 position-relative">
                <img src="/assets/img/clicks.jpg" className="round img-fluid" alt="clicks" />
                <div className="h1 text-white text-center position-absolute" style={{ top: "50%", width: "100%" }}>
                  <span className="badge bg-primary rounded-pill text-uppercase">
                    <PatchCheckFill /> <span className="mt-3">{t("unlimited")}</span>
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Analytics;

import React, { useContext, useEffect, useState } from "react";
import { getAllProspectsInAnalysisWithFilter } from "../../lib/firebase/prospects";
import { UserContext } from "../../contexts/UserContext";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import Table from "./Table";
import { getEmail } from "../../lib/firebase/emails";

function Leads() {
  const { user } = useContext(UserContext) as any;
  const { id: emailId } = useParams() as any;
  const [loading, setLoading] = useState(true);
  const [prospects, setProspects] = useState<any[]>([]);

  const fetchProspects = async () => {
    try {
      setLoading(true);
      const email = await getEmail(user.firebaseUser.uid, emailId);
      if (!email) return;
      const prospects = await getAllProspectsInAnalysisWithFilter(user.firebaseUser.uid, email.analysis, "email");
      console.log("prospects", prospects);
      setProspects(prospects);
    } catch (err: any) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProspects();
  }, []);

  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-header-title">Leads</h4>
      </div>
      <div className="card-body p-0" style={{ maxHeight: "380px", overflowY: "auto" }}>
        <Table users={prospects} />
      </div>
    </div>
  );
}

export default Leads;

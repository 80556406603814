import React, { useContext, useState } from "react";
import { BoxArrowUpRight, Check } from "react-bootstrap-icons";
import Rating from "./Rating";
import { UserContext } from "../contexts/UserContext";
import { useTranslation } from "react-i18next";

function EarnFreeCredits() {
  const { t } = useTranslation("Component.EarnFreeCredits");
  const { user } = useContext(UserContext) as any;
  const [showRatingModal, setShowRatingModal] = useState(false);
  const [reviewCompleted, setReviewCompleted] = useState(user?.firebaseUser?.review?.completed === true);

  const handleShowRatingModal = () => {
    setShowRatingModal(true);
  };

  return (
    <>
      <div className="card" id="earn-free-credits-card">
        <div className="card-header">
          <h4 className="card-header-title">{t("earnFreeCreditsTitle")}</h4>
        </div>
        <div className="card-body" style={{ maxHeight: 320, overflowY: "scroll" }}>
          <div className="list-group list-group-lg list-group-flush list-group-no-gutters">
            {user.firebaseUser?.preferences?.language === "es" && (
              <Item
                title={t("capterraReviewTitle")}
                description={t("capterraReviewDescription")}
                credits="2500"
                completed={user?.firebaseUser?.capterraReview?.completed === true}
                customCta={
                  <a
                    className={`btn btn-xs btn-white ${user?.firebaseUser?.capterraReview?.completed === true ? "disabled" : ""}`}
                    href="https://reviews.capterra.com/products/new/82c84a6e-c82a-4ead-babf-3217247ccc5c/deb5489a-6204-48e6-aec4-1c1d0180566a?lang=es"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="align-middle">{t("complete")}</span> <BoxArrowUpRight className="ml-1" />
                  </a>
                }
              />
            )}
            <Item
              title={t("completeTrainingChallengeTitle")}
              description={t("completeTrainingChallengeDescription")}
              credits="250"
              completed={user?.firebaseUser?.welcomeGuide?.completed === true}
              customCta={
                <a
                  className={`btn btn-xs btn-white ${user?.firebaseUser?.welcomeGuide?.completed === true ? "disabled" : ""}`}
                  href="https://academy.mailerfind.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="align-middle">{t("complete")}</span> <BoxArrowUpRight className="ml-1" />
                </a>
              }
            />
            <Item
              title={t("shareExperienceTitle")}
              description={t("shareExperienceDescription")}
              credits="250"
              completed={reviewCompleted}
              customCta={
                <button className="btn btn-xs btn-white" disabled={reviewCompleted} onClick={handleShowRatingModal}>
                  <span className="align-middle">{t("complete")}</span> <BoxArrowUpRight className="ml-1" />
                </button>
              }
            />
          </div>
        </div>
      </div>
      <Rating show={showRatingModal} setShow={setShowRatingModal} onComplete={() => setReviewCompleted(true)} />
    </>
  );
}

function Item({ title, description, credits, customCta, completed }: any) {
  const { t } = useTranslation("Component.EarnFreeCredits");
  return (
    <div className="list-group-item">
      <div className="media">
        <div className="my-auto mr-3">
          {completed ? (
            <Check size={30} className="text-white border border-success p-1 bg-success" style={{ borderRadius: "999px" }} />
          ) : (
            <div className="border border-success" style={{ width: 30, height: 30, borderRadius: "999px" }}></div>
          )}
        </div>
        <div className="media-body">
          <div className="row justify-content-between">
            <div className="col-md-6">
              <h5 className="mb-1">{completed ? <del>{title}</del> : title}</h5>
              <p className="mb-0">{completed ? <del>{description}</del> : description}</p>
            </div>
            <div className="col-auto my-auto">
              <span className="badge bg-soft-success text-success">
                +{credits} {t("credits")}
              </span>
            </div>
            <div className="col-auto my-auto">{customCta}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EarnFreeCredits;

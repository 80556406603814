import React, { useEffect, useState } from "react";
import TimezoneSelect, { ITimezone, allTimezones, ITimezoneOption } from "react-timezone-select";
import { Email } from "../../types/EmailTypes";
import Skeleton from "react-loading-skeleton";

interface SchedulingProps {
  email: Email;
  setEmail: (value: any) => void;
  setSaved: (value: any) => void;
  loading?: boolean;
}

const customStyles = {
  control: (base: any, state: any) => ({
    ...base,
    borderColor: state.isFocused ? "#e7eaf3" : "#e7eaf3",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? "#e7eaf3" : "#e7eaf3",
    },
  }),
};

function Scheduling({ email, setEmail, setSaved, loading }: SchedulingProps) {
  // Estado local del componente: puede ser null mientras esperamos los datos
  const [selectedTimezone, setSelectedTimezone] = useState<ITimezone>(email.timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone);

  const handleChange = (value: ITimezoneOption) => {
    setSelectedTimezone(value);
    setEmail({ ...email, timeZone: value.value });
    setSaved(false);
  };

  return (
    <div className="card">
      <div className="card-header">
        <h5 className="card-title">Time Zone</h5>
      </div>
      <div className="card-body">
        <p>
          Select the time zone for your email campaign. Your emails will be scheduled according to local working hours (e.g., 8:00–19:00) on
          weekdays in the chosen time zone. This helps ensure recipients receive your messages at the most appropriate times.
        </p>
        <div className="select-wrapper">
          {loading ? (
            <Skeleton height={35} />
          ) : (
            <TimezoneSelect
              value={selectedTimezone}
              onChange={handleChange}
              styles={customStyles}
              timezones={{
                ...allTimezones,
                "America/Lima": "Pittsburgh",
                "Europe/Berlin": "Frankfurt",
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Scheduling;

import React, { useContext, useEffect, useState } from "react";
import { CloudDownload } from "react-bootstrap-icons";
import { useParams } from "react-router-dom";
import Error from "../Error";
import toast from "react-hot-toast";
import { getAnalysisById } from "../../lib/firebase/analysis";
import { UserContext } from "../../contexts/UserContext";
import Loading from "../../components/Loading";
import { useTranslation } from "react-i18next";
import { getExportById } from "../../lib/firebase/exports";
import { Export } from "../../types/ExportTypes";

function Download() {
  let { id: exportId } = useParams();
  const { user } = useContext(UserContext) as any;
  const { t } = useTranslation("Download.index");

  const [loading, setLoading] = useState(true);
  const [analysis, setAnalysis] = useState(null) as any;
  const [exportDoc, setExport] = useState<Export | null>(null);

  const fetchData = async () => {
    if (!exportId) return;
    try {
      setLoading(true);
      const exportDoc = await getExportById(user.firebaseUser.uid, exportId);
      const analysis = await getAnalysisById(user.firebaseUser.uid, exportDoc.analysis.id);
      setExport(exportDoc);
      setAnalysis(analysis);
    } catch (err: any) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) return <Loading />;
  if (!exportId) return <Error />;

  if (!exportDoc) return <Loading />;

  return (
    <div className="bg-light vh-100 d-flex justify-content-center align-items-center">
      <div className="container">
        <div className="card mx-auto" style={{ maxWidth: "30rem" }}>
          <div className="card-header">
            <h5 className="card-title">{t("pageTitle")}</h5>
          </div>
          <div className="card-body">
            {exportDoc.status === "completed" && <p>{t("congratulationsMessage")}</p>}
            {exportDoc.status === "failed" && <p>{t("errorMessage")}</p>}
            {exportDoc.status === "processing" && <p>{t("processingMessage")}</p>}
            <div className="bg-dark text-white p-4 rounded">
              <div className="row">
                <div className="col-2">
                  {exportDoc.format === "csv" && (
                    <img className="avatar avatar mr-2" src="/assets/svg/components/placeholder-csv-format.svg" alt={t("csvAltText")} />
                  )}
                  {exportDoc.format === "excel" && (
                    <img className="avatar avatar mr-2" src="/assets/svg/brands/excel.svg" alt={t("csvAltText")} />
                  )}
                  {!exportDoc.format && (
                    <img className="avatar avatar mr-2" src="/assets/svg/components/placeholder-img-format.svg" alt="Unknown" />
                  )}
                </div>
                <div className="col-10">
                  <h5 className="text-white">
                    {analysis?.name}.{exportDoc.format === "csv" ? "csv" : "xlsx"}
                  </h5>
                  <p>
                    <b>{t("idLabel")}:</b> {analysis.id}
                  </p>
                </div>
              </div>
              {exportDoc.downloadUrl ? (
                <a className="btn btn-primary w-100" href={exportDoc.downloadUrl}>
                  <CloudDownload className="mr-1" /> <b>{t("downloadButtonText")}</b>
                </a>
              ) : (
                <button className="btn btn-primary w-100" disabled>
                  <CloudDownload className="mr-1" /> <b>{t("downloadButtonText")}</b>
                </button>
              )}

              {/*<p className="text-center m-0" style={{ cursor: "pointer" }} onClick={handleOpenInGoogleSheets}>
                <u>
                  <img src="/assets/img/gsheets.png" className="img-fluid mr-1 mb-1" style={{ width: 16 }} alt="Google Sheets Icon" />
                  <span className="align-middle">Import to Google Sheets</span> <BoxArrowUpRight size={10} className="mb-1" />
                </u>
            </p>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Download;

import {
  collection,
  doc,
  getDoc,
  limit as firebaseLimit,
  getDocs,
  orderBy,
  query,
  where,
  QueryConstraint,
  onSnapshot,
} from "firebase/firestore";
import { db } from "./firebase";

/**
 * Tamaño de página utilizado para limitar la cantidad de exportaciones en cada consulta.
 */
const PAGE_SIZE = 100;

/**
 * Obtiene todos los documentos de exportación de un usuario específico.
 * @param {string} userId - ID del usuario cuyos exportaciones se quieren obtener.
 * @returns {Promise<any[]>} - Una lista de exportaciones del usuario.
 */
export const getExports = async (userId: string, analysisId?: string, enriched: boolean = false): Promise<any[]> => {
  // Referencia a la colección de exports del usuario
  const exportsCollectionRef = collection(db, "users", userId, "exports");

  // Declaramos la lista de constraints con un tipo genérico válido para todas
  let constraints: QueryConstraint[] = [orderBy("createdAt", "desc"), firebaseLimit(PAGE_SIZE)];

  // Si se especifica analysisId, añadimos la condición where
  if (analysisId) {
    const analysisRef = doc(db, "users", userId, "analysis", analysisId);
    constraints.push(where("analysis", "==", analysisRef));
  }

  // Construimos la query con todos los constraints
  const q = query(exportsCollectionRef, ...constraints);

  // Ejecutamos la query
  const querySnapshot = await getDocs(q);

  // Recorremos los resultados y los almacenamos en un array
  const exports: any[] = [];
  querySnapshot.forEach((doc) => {
    exports.push({ id: doc.id, ...doc.data() });
  });

  return exports;
};

/**
 * Escucha los cambios en tiempo real de la colección "exports" de un usuario,
 * con opción de filtrar por analysisId.
 *
 * @param {string} userId - ID del usuario al que pertenecen las exportaciones.
 * @param {string} [analysisId] - Filtra la colección por este campo si se proporciona.
 * @param {(exports: any[]) => void} callback - Función que recibe un array de documentos (exports) cuando hay cambios.
 * @returns {Function} - Una función de limpieza (unsubscribe) para dejar de escuchar los cambios.
 */
export function listenExports(userId: string, analysisId: string | undefined, callback: (exports: any[]) => void): () => void {
  // Construimos la referencia a la colección
  const exportsCollectionRef = collection(db, "users", userId, "exports");

  // Preparamos las restricciones (orderBy, limit, where, etc.)
  let constraints: QueryConstraint[] = [orderBy("createdAt", "desc"), firebaseLimit(PAGE_SIZE)];

  if (analysisId) {
    constraints.push(where("analysisId", "==", analysisId));
  }

  // Creamos la query con las restricciones
  const exportsQuery = query(exportsCollectionRef, ...constraints);

  // Suscribimos a onSnapshot para escuchar los cambios en tiempo real
  const unsubscribe = onSnapshot(exportsQuery, (snapshot) => {
    const exportDocs: any[] = [];
    snapshot.forEach((doc) => {
      exportDocs.push({ id: doc.id, ...doc.data() });
    });
    // Invocamos el callback pasándole los datos
    callback(exportDocs);
  });

  // Devolvemos la función de limpieza (unsubscribe)
  return unsubscribe;
}

/**
 * Obtiene un único documento de exportación de un usuario por su ID de exportación.
 * @param {string} userId - ID del usuario propietario del export.
 * @param {string} exportId - ID específico del export que se quiere obtener.
 * @returns {Promise<any | null>} - El export especificado o null si no existe.
 */
export const getExportById = async (userId: string, exportId: string): Promise<any | null> => {
  const exportDocRef = doc(db, "users", userId, "exports", exportId);
  const exportDoc = await getDoc(exportDocRef);

  return exportDoc.exists() ? { id: exportDoc.id, ...exportDoc.data() } : null;
};

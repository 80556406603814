import { BACKEND_URL } from "../../utils/constants";
import makeRequest from "../client";

export const generateEmailBody = async (generateEmailContent: object, jwt: string): Promise<any> => {
  try {
    const response = await makeRequest({
      url: `${BACKEND_URL}/v1/ai/email/generate-content`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
      body: {
        generateEmailContent,
      },
    });

    return response;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const improveEmailBody = async (content: string, mode: string, jwt: string): Promise<any> => {
  try {
    const response = await makeRequest({
      url: `${BACKEND_URL}/v1/ai/email/improve-content`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
      body: {
        content,
        mode,
      },
    });

    return response;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const changeContentTone = async (content: string, mode: string, jwt: string): Promise<any> => {
  try {
    const response = await makeRequest({
      url: `${BACKEND_URL}/v1/ai/email/change-content-tone`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
      body: {
        content,
        mode,
      },
    });

    return response;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

export const translateEmailBody = async (content: string, language: string, jwt: string): Promise<any> => {
  try {
    const response = await makeRequest({
      url: `${BACKEND_URL}/v1/ai/email/translate-content`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
      body: {
        content,
        language,
      },
    });

    return response;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

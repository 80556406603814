import React, { useRef, useState, useEffect, useContext } from "react";
import { Braces, ChevronDown, EnvelopeCheck, Magic } from "react-bootstrap-icons";
import Quill from "quill";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { EMAIL_AI_OPTIONS } from "../utils/constants";
import AddUnsubscribeLink from "./AddUnsubscribeLink";
import { useTranslation } from "react-i18next";

let Inline = Quill.import("blots/inline");

class VariableBlot extends Inline {
  static blotName = "variable";
  static tagName = "span";
  static className = "ql-variable";

  static formats(): boolean {
    return true;
  }
}

Quill.register("formats/variable", VariableBlot);

function RichTextEditor(props: any) {
  const { t } = useTranslation("Component.RichTextEditor");
  const quillRef = useRef(null) as any;
  const [showVariablesDropdown, setShowVariablesDropdown] = useState(false);
  const [showEmailAiDropdown, setShowEmailAiDropdown] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(0);

  const [showCustomUnsubscribe, setShowCustomUnsubscribe] = useState(false);

  const modules = {
    toolbar: {
      container: "#toolbar",
    },
  };

  useEffect(() => {
    if (quillRef && quillRef.current) {
      quillRef.current.getEditor().on("text-change", () => {
        const range = quillRef.current.getEditor().getSelection();
        if (range) {
          setCursorPosition(range.index);
        }
      });
    }
  }, []);

  const handleAddCustomUnsubscribeText = async (text: string) => {
    const variableText = `<a href={{&nbsp;unsubscribe_link&nbsp;}}>${text}</a>`;
    quillRef.current.getEditor().clipboard.dangerouslyPasteHTML(cursorPosition, variableText);
    quillRef.current.getEditor().formatText(cursorPosition, variableText.length, "variable", true);
    setShowCustomUnsubscribe(false);
  };

  const handleSelectChange = (value: string) => {
    const variableText = `{{ ${value} }}`;
    if (value === "unsubscribe_link") {
      setShowVariablesDropdown(false);
      return setShowCustomUnsubscribe(true);
    }
    quillRef.current.getEditor().insertText(cursorPosition, variableText);
    quillRef.current.getEditor().formatText(cursorPosition, variableText.length, "variable", true);
    setShowVariablesDropdown(false);
  };

  const handleEmailAiChange = (option: string) => {
    props.openEmailAiModal(option);
    setShowEmailAiDropdown(false);
  };

  return (
    <div>
      <div id="toolbar">
        <button className="ql-bold mt-1"></button>
        <button className="ql-italic mt-1"></button>
        <button className="ql-underline mt-1"></button>
        <button className="ql-strike mt-1"></button>
        <select className="ql-color"></select>
        <select className="ql-background"></select>
        <button className="ql-list mt-1" value="ordered"></button>
        <button className="ql-list mt-1" value="bullet"></button>
        <select className="ql-align"></select>
        <button className="ql-link mt-1"></button>
        <button className="ql-image mt-1"></button>
        <button className="ql-clean mt-1"></button>
        {(props?.showVariables || false) && (
          <div style={{ position: "relative", display: "inline-block", width: "105px" }} className="mt-1 ml-1">
            <button
              type="button"
              style={{ fontSize: "14px", width: "150px", textAlign: "left" }}
              onClick={() => setShowVariablesDropdown(!showVariablesDropdown)}
            >
              <Braces className="mr-1" style={{ marginTop: 2 }} />
              <span className="mr-1">{t("variables")}</span>
              <span className="d-inline-block align-middle">
                <ChevronDown size={10} />
              </span>
            </button>
            {showVariablesDropdown && (
              <div
                style={{
                  position: "absolute",
                  background: "#fff",
                  zIndex: 100,
                  padding: "4px",
                }}
                className="dropdown-menu dropdown-menu-end mt-1 show"
              >
                <span className="dropdown-header" style={{ fontSize: "0.65625rem" }}>
                  {t("user")}
                </span>
                <div
                  style={{ cursor: "pointer", borderRadius: ".3125rem" }}
                  className="dropdown-item"
                  onClick={() => handleSelectChange("username")}
                >
                  {t("username")}
                </div>
                <div
                  style={{ cursor: "pointer", borderRadius: ".3125rem" }}
                  className="dropdown-item"
                  onClick={() => handleSelectChange("first_name")}
                >
                  {t("firstName")}
                </div>
                <div
                  style={{ cursor: "pointer", borderRadius: ".3125rem" }}
                  className="dropdown-item"
                  onClick={() => handleSelectChange("email")}
                >
                  {t("email")}
                </div>
                <div
                  style={{ cursor: "pointer", borderRadius: ".3125rem" }}
                  className="dropdown-item"
                  onClick={() => handleSelectChange("website")}
                >
                  {t("website")}
                </div>
                <div
                  style={{ cursor: "pointer", borderRadius: ".3125rem" }}
                  className="dropdown-item"
                  onClick={() => handleSelectChange("phone")}
                >
                  {t("phone")}
                </div>
                <span className="dropdown-header" style={{ fontSize: "0.65625rem" }}>
                  {t("other")}
                </span>
                <div
                  style={{ cursor: "pointer", borderRadius: ".3125rem" }}
                  className="dropdown-item"
                  onClick={() => handleSelectChange("unsubscribe_link")}
                >
                  {t("unsubscribeLink")}
                </div>
              </div>
            )}
          </div>
        )}
        {props?.showEmailAi && (
          <div
            style={{
              position: "relative",
              display: "inline-block",
              width: "105px",
            }}
            className="mt-1"
          >
            <button
              type="button"
              id="email-ai-dropdown"
              style={{ fontSize: "14px", width: "100px", textAlign: "left" }}
              onClick={() => setShowEmailAiDropdown(!showEmailAiDropdown)}
            >
              <Magic className="mr-1" style={{ marginTop: 2 }} />
              <span className="mr-1 colored-text">{t("emailAi")}</span>
              <span className="d-inline-block align-middle">
                <ChevronDown size={10} />
              </span>
            </button>
            {showEmailAiDropdown && (
              <div
                style={{
                  position: "absolute",
                  background: "#fff",
                  zIndex: 100,
                  padding: "4px",
                }}
                className="dropdown-menu dropdown-menu-end mt-1 show"
              >
                <div
                  style={{ cursor: "pointer", borderRadius: ".3125rem" }}
                  className="dropdown-item"
                  onClick={() => handleEmailAiChange(EMAIL_AI_OPTIONS.WRITE)}
                >
                  {t("writeEmail")}
                </div>
                <div
                  style={{ cursor: "pointer", borderRadius: ".3125rem" }}
                  className={`dropdown-item ${props?.value?.length < 50 && "disabled"}`}
                  onClick={() => handleEmailAiChange(EMAIL_AI_OPTIONS.IMPROVE)}
                >
                  {t("improveWriting")}
                </div>
                <div
                  style={{ cursor: "pointer", borderRadius: ".3125rem" }}
                  className={`dropdown-item ${props?.value?.length < 50 && "disabled"}`}
                  onClick={() => handleEmailAiChange(EMAIL_AI_OPTIONS.TONE)}
                >
                  {t("changeTone")}
                </div>
                <div
                  style={{ cursor: "pointer", borderRadius: ".3125rem" }}
                  className={`dropdown-item ${props?.value?.length < 50 && "disabled"}`}
                  onClick={() => handleEmailAiChange(EMAIL_AI_OPTIONS.TRANSLATE)}
                >
                  {t("translate")}
                </div>
              </div>
            )}
          </div>
        )}
        {props?.showTemplates && (
          <div
            style={{
              position: "relative",
              display: "inline-block",
              width: "105px",
            }}
            className="mt-1"
          >
            <button
              type="button"
              id="templates-dropdown"
              style={{ fontSize: "14px", width: "150px", textAlign: "left" }}
              onClick={() => props.openTemplatesModal()}
            >
              <EnvelopeCheck style={{ marginTop: 2 }} />
              <span className="ml-1">{t("templates")} </span>
              <span className="d-inline-block align-middle">
                <ChevronDown size={10} />
              </span>
            </button>
          </div>
        )}
      </div>
      <ReactQuill
        ref={quillRef}
        theme="snow"
        modules={modules}
        style={{
          height: "300px",
          borderRadius: "5px",
        }}
        value={props.value}
        onChange={props.setValue}
      />
      <AddUnsubscribeLink show={showCustomUnsubscribe} setShow={setShowCustomUnsubscribe} handleSubmit={handleAddCustomUnsubscribeText} />
    </div>
  );
}

export default RichTextEditor;
